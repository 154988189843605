import { eventLogClientRequest, workspaceClientRequest } from '../../api'

export async function logEvent(eventType, eventData) {
    const params = {
        eventType,
        eventData, // Pass the actual object/array here
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }

    const userID = localStorage.getItem('userID')
    const workspaceID = localStorage.getItem('workspaceID')
    const userName = localStorage.getItem('userName')

    if (userID) params.userID = userID
    if (workspaceID) params.workspaceID = workspaceID
    if (userName) params.email = userName

    const data = JSON.stringify(params)

    // Construct payload for Lambda
    const payload = {
        action: 'logEvent',
        data, // Send the encrypted string
    }

    // Send the payload to Lambda
    return await eventLogClientRequest({
        method: 'post',
        data: payload, // Pass the object, not a string
    })
}

export const logError = async (message, stack) => {
    const params = {}
    const userID = localStorage.getItem('userID')
    const workspaceID = localStorage.getItem('workspaceID')
    const userName = localStorage.getItem('userName')
    const path = window.location.pathname
    if (userName) params.email = userName
    if (workspaceID) params.workspaceID = workspaceID
    if (path.includes('canvas/')) params.canvasID = path.split('/')[2]
    if (userID) params.userID = userID
    params.message = message
    params.stack = stack
    const data = JSON.stringify(params)
    const payload = { action: 'debug', data }
    return await workspaceClientRequest({
        method: 'post',
        data: payload,
    })
}
