import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    addSavedFilter,
    deleteInbox,
    deleteMetric,
    deleteSavedFilter,
    getWorkspaceMetadata,
    saveNewMetric,
    saveNewTheme,
    validateMetricFormula,
} from './workspaceMetadataApi'
import { getWorkspaceID } from '../../../utils'

export const useWorkspaceMetadata = (tableStats = false) => {
    const queryClient = useQueryClient()
    const workspaceID = getWorkspaceID()

    const { data, isLoading, refetch, error, isFetching } = useQuery({
        queryKey: ['workspaceMetadata', workspaceID, tableStats],
        queryFn: () => getWorkspaceMetadata(tableStats),
        enabled: !!workspaceID,
    })

    const { mutate: saveKPI, isPending: saveKPIPending } = useMutation({
        mutationFn: ({ metric }) => saveNewMetric(metric),
        onSuccess: (d, v) => {
            queryClient.invalidateQueries(['workspaceMetadata', workspaceID])
            v.onSuccess && v.onSuccess()
        },
    })

    const { mutate: deleteKPI, isPending: deleteKPIPending } = useMutation({
        mutationFn: ({ metricID }) => deleteMetric(metricID),
        onSuccess: (d, v) => {
            queryClient.invalidateQueries(['workspaceMetadata', workspaceID])
            v.onSuccess && v.onSuccess()
        },
    })

    const { mutate: saveFilter, isPending: saveFilterPending } = useMutation({
        mutationFn: ({ filterName, filter, filterKey }) =>
            addSavedFilter(workspaceID, filterName, filter, filterKey),
        onSuccess: (data, variables) => {
            if (variables?.onSuccess) {
                refetch().then((res) => {
                    variables.onSuccess(res.data)
                })
            } else {
                queryClient.invalidateQueries(['workspaceMetadata', workspaceID])
            }
        },
    })

    const { mutate: deleteFilter } = useMutation({
        mutationFn: ({ filterKey }) => deleteSavedFilter(filterKey),
        onSuccess: (data, variables) => {
            if (variables?.onSuccess) variables.onSuccess(data)
            queryClient.invalidateQueries(['workspaceMetadata', workspaceID])
        },
        onError: (error, variables) => {
            if (variables?.onError) variables.onError(error)
        },
    })

    const { mutate: saveTheme, isPending: isSaveThemeLoading } = useMutation({
        mutationFn: ({ themeName, canvasID, chartPreferences, colorScheme, fonts }) =>
            saveNewTheme(themeName, canvasID, chartPreferences, colorScheme, fonts),
        onSuccess: () => {
            queryClient.invalidateQueries(['workspaceMetadata', workspaceID])
        },
        onSettled: (data, error, variables) => {
            if (variables?.onSettled) variables.onSettled()
        },
    })

    const { mutate: deleteSource } = useMutation({
        mutationFn: ({ inboxID }) => deleteInbox(inboxID),
        onSuccess: (data, variables) => {
            if (variables?.onSuccess) variables.onSuccess(data)
        },
        onError: (error, variables) => {
            if (variables?.onError) variables.onError(error)
        },
    })

    const { mutate: validateFormula, isPending: validateFormulaPending } = useMutation({
        mutationFn: ({ formula, format }) => validateMetricFormula(formula, format),
        onSuccess: (data, variables) => {
            variables.onSuccess && variables.onSuccess(data)
        },
    })

    return {
        workspaceMetadata: data,
        isLoading,
        isFetching,
        refetch,
        error,
        saveKPI,
        saveKPIPending,
        deleteKPI,
        deleteKPIPending,
        saveFilter,
        saveFilterPending,
        deleteFilter,
        saveTheme,
        isSaveThemeLoading,
        deleteSource,
        validateFormula,
        validateFormulaPending,
    }
}
