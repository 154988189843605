import { useMutation } from '@tanstack/react-query'
import { runBot } from './botActionsApi'

export const useBotActions = () => {
    const { mutate, isPending } = useMutation({
        mutationFn: ({ inboxID }) => runBot(inboxID),
        onSuccess: (response, variables) => {
            variables?.onSuccess(response)
        },
        onError: (error, variables) => {
            variables?.onError(error)
        },
    })

    return {
        runBot: mutate,
        isRunning: isPending,
    }
}
