import React, { useEffect, useRef, useState } from 'react'
import './ScoopDatePicker.css'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
import { Box, Popover, Typography } from '@mui/material'
import { CalendarIcon } from './CalendarIcon'
import { getCalendarOverrides } from './ClandarOverrides'
import Button from './Button'

const shortDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const rangeOptions = [
    'Last 7 Days',
    'Last 14 Days',
    'Last Month',
    'Last 2 Months',
    'Last 6 Months',
    'Last Year',
]

export const ScoopDatePicker = ({
    value,
    onChange,
    range,
    containerSx,
    label,
    isGuestMode,
    theme,
}) => {
    const [innerValue, setInnerValue] = useState(value)
    const [showPicker, setShowPicker] = useState(false)
    const [memoRangeOption, setMemoRangeOption] = useState('')
    const [rangeOption, setRangeOption] = useState('')
    const anchor = useRef()

    useEffect(() => {
        const head = document.head
        if (showPicker) {
            const calendarStyles = document.createElement('style')
            calendarStyles.setAttribute('id', 'calendar-temp-styles')
            head.appendChild(calendarStyles)
            calendarStyles.textContent = getCalendarOverrides(
                theme?.colorScheme?.backgroundColor || '',
                theme?.colorScheme?.darkTheme ? 'white' : ''
            )
        } else {
            const removeElement = document.getElementById('calendar-temp-styles')
            if (removeElement) setTimeout(() => head.removeChild(removeElement), 300)
        }
    }, [showPicker])

    useEffect(() => {
        setInnerValue(null)
    }, [range])

    useEffect(() => {
        if (showPicker) {
            setInnerValue(value)
            setMemoRangeOption(rangeOption)
        }
    }, [showPicker])

    const handleDateChange = (value) => {
        setInnerValue(value)
        setRangeOption('')
    }

    const handleRangeOptionSelect = (option) => {
        setRangeOption(option)
        const today = new Date()
        const startDate = new Date()
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth)
        lastDayOfLastMonth.setDate(lastDayOfLastMonth.getDate() - 1)
        switch (option) {
            case rangeOptions[0]:
                startDate.setDate(today.getDate() - 7)
                setInnerValue([startDate, today])
                break
            case rangeOptions[1]:
                startDate.setDate(today.getDate() - 14)
                setInnerValue([startDate, today])
                break
            case rangeOptions[2]:
                const firstDayOfLastMonth = new Date(firstDayOfCurrentMonth)
                firstDayOfLastMonth.setMonth(firstDayOfLastMonth.getMonth() - 1)
                setInnerValue([firstDayOfLastMonth, lastDayOfLastMonth])
                break
            case rangeOptions[3]:
                const firstDayOfLastTwoMonths = new Date(firstDayOfCurrentMonth)
                firstDayOfLastTwoMonths.setMonth(firstDayOfLastTwoMonths.getMonth() - 2)
                setInnerValue([firstDayOfLastTwoMonths, lastDayOfLastMonth])
                break
            case rangeOptions[4]:
                const firstDayOfLastSixMonths = new Date(firstDayOfCurrentMonth)
                firstDayOfLastSixMonths.setMonth(firstDayOfLastSixMonths.getMonth() - 6)
                setInnerValue([firstDayOfLastSixMonths, lastDayOfLastMonth])
                break
            case rangeOptions[5]:
                const firstDayOfLastYear = new Date(firstDayOfCurrentMonth)
                firstDayOfLastYear.setMonth(firstDayOfLastYear.getMonth() - 12)
                setInnerValue([firstDayOfLastYear, lastDayOfLastMonth])
                break
        }
    }

    const handleCancel = () => {
        setShowPicker(false)
        if (memoRangeOption !== rangeOption) setRangeOption(memoRangeOption)
    }

    const handleApply = () => {
        onChange(innerValue)
        setShowPicker(false)
    }

    const getStringValue = () => {
        if (!value) {
            if (range) {
                return 'Select date range'
            } else {
                return 'Select date'
            }
        } else {
            if (range) {
                return Array.isArray(value)
                    ? value[0].toLocaleDateString('en-US') +
                          ' - ' +
                          value[1].toLocaleDateString('en-US')
                    : ''
            } else {
                return Array.isArray(value) ? '' : value.toLocaleDateString('en-US')
            }
        }
    }

    const getInnerValueDateString = () => {
        if (!innerValue) {
            return ''
        } else {
            if (range) {
                return Array.isArray(innerValue)
                    ? innerValue[0].toDateString() + ' - ' + innerValue[1].toDateString()
                    : ''
            } else {
                return Array.isArray(innerValue) ? '' : innerValue.toDateString()
            }
        }
    }

    return (
        <>
            <Box className={'date-picker-container'} sx={containerSx}>
                {label && (
                    <Typography className={'inter'} sx={{ fontWeight: 600, fontSize: '12px' }}>
                        {label}
                    </Typography>
                )}
                <Box
                    className={'date-picker-input'}
                    ref={anchor}
                    onClick={() => !isGuestMode && setShowPicker(true)}
                    sx={{
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                        color: theme?.colorScheme?.darkTheme ? 'white' : '',
                        border: theme?.colorScheme?.darkTheme
                            ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                            : '',
                    }}
                >
                    <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                        {getStringValue()}
                    </Typography>
                    <CalendarIcon fill={theme?.colorScheme?.darkTheme ? 'white' : '#343330'} />
                </Box>
            </Box>
            <Popover
                open={showPicker}
                anchorEl={anchor.current}
                onClose={handleCancel}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                container={document.getElementById('slide-container')}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                        backdropFilter:
                            theme?.colorScheme?.backgroundColor === '#00000000' ? 'blur(20px)' : '',
                    },
                }}
            >
                <Box
                    className={'date-picker-popup'}
                    sx={{
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                    }}
                >
                    <Box className={'options-and-calendar'}>
                        {range && (
                            <Box className={'range-options-container'}>
                                {rangeOptions.map((option) => (
                                    <Box
                                        className={`range-option ${option === rangeOption ? 'range-option-selected' : ''}`}
                                        onClick={() => handleRangeOptionSelect(option)}
                                    >
                                        <Typography
                                            className={'inter'}
                                            sx={{
                                                color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {option}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        <Box className={'calendar-container'}>
                            <Calendar
                                value={innerValue}
                                onChange={handleDateChange}
                                formatShortWeekday={(locale, date) => shortDays[date.getDay()]}
                                selectRange={range}
                            />
                        </Box>
                    </Box>
                    <Box className={'date-picker-footer'}>
                        <Box sx={{ flex: 1 }}>
                            <Typography
                                className={'inter'}
                                sx={{
                                    fontSize: '14px',
                                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                }}
                            >
                                {getInnerValueDateString()}
                            </Typography>
                        </Box>
                        <Button onClick={handleCancel} className={'date-picker-button'}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleApply}
                            className={'date-picker-button iq-button-purple'}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    )
}
