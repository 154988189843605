import { mobileAPIRequest } from '../../api'
import { setOnboardingChecklist } from '../../../store/actions/uiActions'

const checkIfRedirect = (createDate, dispatch) => {
    const fiveMinutesInMilliseconds = 5 * 60 * 1000
    const now = Date.now()

    const sfDate = new Date(createDate)

    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        timeZoneName: 'short',
    })

    const timeZoneName = formatter
        .formatToParts(sfDate)
        .find((part) => part.type === 'timeZoneName').value

    const sfOffsetHours = timeZoneName === 'PST' ? -8 : -7
    const sfOffsetMilliseconds = sfOffsetHours * 60 * 60 * 1000

    const createDateUTC = createDate - sfOffsetMilliseconds

    if (now - createDateUTC < fiveMinutesInMilliseconds) {
        if (!localStorage.getItem('redirectTour')) localStorage.setItem('redirectTour', 'true')
        if (!localStorage.getItem('personalWorkspaceOnboarding'))
            localStorage.setItem('personalWorkspaceOnboarding', 'true')
        if (!localStorage.getItem('onboardingChecklist')) dispatch(setOnboardingChecklist('0'))
    }
}

export const getUserInfo = async (userID, dispatch) => {
    const action = {
        action: 'getObjects',
        attributeName: 'userID',
        attributeValue: userID,
        class: 'scoop.user.User',
    }
    const result = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    if (result && Array.isArray(result.objects)) {
        checkIfRedirect(result.objects[0].createDate, dispatch)
        if (localStorage.getItem('userFirstName') === 'undefined')
            localStorage.setItem('userFirstName', result.objects[0].firstName)
        return result.objects[0]
    }
}
