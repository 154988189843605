import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ScoopBlack from '../../../assets/images/ScoopBlack.png'
import QuestionnaireImg from '../../../assets/images/QustionnaireImg.png'
import Typography from '@mui/material/Typography'
import Button from '../../common/Button/Button'
import Checkbox from '../../common/Checkbox/Checkbox'
import Input from '../../common/Input/Input'
import { useUserSurvey } from '../../../api/hooks/survey/useUserSurvey'
import { useSelector } from 'react-redux'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { useUserInfo } from '../../../api/hooks/user/useUserInfo'
import { logEvent } from '../../../api/hooks/logs/api'

const FIRST_STEP = [
    'Accounting/Finance',
    'Marketing',
    'Sales',
    'Customer Success',
    'Operations',
    'Engineering / IT',
    'Business Owner / Founder',
    'Other (please specify)',
]

const SECOND_STEP = [
    'Track key business metrics in one place to simplify monitoring and decision-making.',
    'Use AI to uncover patterns, identify trends, and discover hidden opportunities.',
    'Streamline reporting with automated, professional summaries for stakeholders.',
    'Visualize data from multiple sources to explore and communicate insights clearly.',
    'Set up real-time alerts and predictive forecasts to take proactive actions.',
    'Other (please specify your specific needs).',
]

const TITLES = [
    {
        title: 'What best describes your current role?',
        description: 'We’ll use this to tailor your experience with Scoop.',
    },
    {
        title: 'What are your goals with Scoop?',
        description: 'This helps us recommend the best features for your needs.',
    },
    {
        title: 'What is your company name and website?',
        description: 'This helps us understand your business better and offer tailored insights.',
    },
]

export const Questionnaire = () => {
    const [step, setStep] = useState(0)
    const [selectedRole, setSelectedRole] = useState(null)
    const [otherSelectedRole, setOtherSelectedRole] = useState('')
    const [selectedGoals, setSelectedGoals] = useState([])
    const [otherSelectedGoal, setOtherSelectedGoal] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyWebsite, setCompanyWebsite] = useState('')
    const [errors, setErrors] = useState({
        otherSelectedRole: '',
        otherSelectedGoal: '',
        companyName: '',
        companyWebsite: '',
    })

    const [shuffledFirstStep, setShuffledFirstStep] = useState([])
    const [shuffledSecondStep, setShuffledSecondStep] = useState([])

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

    const { createSurvey, isSubmitting } = useUserSurvey()
    const { user } = useUserInfo()

    const userID = user?.userID
    const navigate = useNavigate()

    useEffect(() => {
        const shuffledStepOne = [...FIRST_STEP.slice(0, -1)]
            .sort(() => Math.random() - 0.5)
            .concat(FIRST_STEP[FIRST_STEP.length - 1])
        setShuffledFirstStep(shuffledStepOne)

        const shuffledStepTwo = [...SECOND_STEP.slice(0, -1)]
            .sort(() => Math.random() - 0.5)
            .concat(SECOND_STEP[SECOND_STEP.length - 1])
        setShuffledSecondStep(shuffledStepTwo)
    }, [])

    useEffect(() => {
        if (user?.createDate) {
            if (checkIfRedirect(user.createDate)) navigate(ROUTES.CANVAS_DASHBOARD)
        }
    }, [user])

    useEffect(() => {
        if (!isAuthenticated) navigate(ROUTES.LOGIN)
    }, [isAuthenticated])

    const areInputsValid = () => {
        const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
        let isValid = true
        if (companyName.length < 2) {
            setErrors((prevState) => ({
                ...prevState,
                companyName: 'Company name must be at least 2 characters long',
            }))
            isValid = false
        } else {
            setErrors((prevState) => ({
                ...prevState,
                companyName: '',
            }))
        }
        if (!urlRegex.test(companyWebsite)) {
            setErrors((prevState) => ({
                ...prevState,
                companyWebsite: 'Please enter a valid URL',
            }))
            isValid = false
        } else {
            setErrors((prevState) => ({
                ...prevState,
                companyWebsite: '',
            }))
        }
        return isValid
    }

    const handleNext = () => {
        switch (step) {
            case 0:
                if (
                    otherSelectedRole.length === 0 &&
                    selectedRole === FIRST_STEP[FIRST_STEP.length - 1]
                ) {
                    setErrors((prevState) => ({
                        ...prevState,
                        otherSelectedRole: 'Make sure to specify your role',
                    }))
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        otherSelectedRole: '',
                    }))
                    setStep(step + 1)
                    logEvent('postSignupFlow', {
                        type: 'role',
                        completedStep: '1',
                        selected: otherSelectedRole.length > 0 ? otherSelectedRole : selectedRole,
                        userID,
                    })
                }
                break
            case 1:
                if (
                    selectedGoals.length === 0 ||
                    (selectedGoals.includes(SECOND_STEP[SECOND_STEP.length - 1]) &&
                        !otherSelectedGoal)
                ) {
                    setErrors((prevState) => ({
                        ...prevState,
                        otherSelectedGoal: 'Make sure to specify your goal',
                    }))
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        otherSelectedGoal: '',
                    }))
                    setStep(step + 1)
                    logEvent('postSignupFlow', {
                        type: 'goals',
                        completedStep: '2',
                        selected:
                            otherSelectedGoal.length > 0
                                ? [...selectedGoals, otherSelectedGoal]
                                : [...selectedGoals],
                        userID,
                    })
                }
                break
            case 2:
                if (!areInputsValid()) return
                const formattedGoals = selectedGoals.includes(SECOND_STEP[SECOND_STEP.length - 1])
                    ? [...selectedGoals, otherSelectedGoal]
                    : [...selectedGoals]

                createSurvey({
                    role:
                        selectedRole === FIRST_STEP[FIRST_STEP.length - 1]
                            ? otherSelectedRole
                            : selectedRole,
                    goals: JSON.stringify(formattedGoals),
                    companyName,
                    companyWebsite,
                    userID,
                    onSuccess: () => navigate(ROUTES.CANVAS_DASHBOARD),
                })
                logEvent('postSignupFlow', {
                    type: 'company',
                    completedStep: '3',
                    selected: {
                        companyName,
                        companyURL: companyWebsite,
                    },
                    userID,
                })
                break
            default:
                setStep(step + 1)
                break
        }
    }

    const isNextEnabled = () => {
        switch (step) {
            case 0:
                return !!selectedRole
            case 1:
                return selectedGoals.length > 0
            case 2:
                return true
            default:
                return true
        }
    }

    const renderStepOne = () => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
                {shuffledFirstStep.map((role) => (
                    <Box
                        onClick={() => setSelectedRole(role)}
                        sx={{
                            padding: '8px 16px',
                            border: selectedRole === role ? '1px solid #e50b54' : '1px solid gray',
                            backgroundColor: selectedRole === role ? '#fefafc' : 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: selectedRole === role ? 'none' : '#F2F2F2',
                            },
                        }}
                        key={role}
                    >
                        <Typography>{role}</Typography>
                    </Box>
                ))}
                {selectedRole === FIRST_STEP[FIRST_STEP.length - 1] && (
                    <Box sx={{ width: '70%' }}>
                        <Input
                            padding={'8px'}
                            width={'50%'}
                            value={otherSelectedRole}
                            onChange={(event) => setOtherSelectedRole(event.target.value)}
                            label={'Please specify your role'}
                        />
                        {errors.otherSelectedRole && (
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'red',
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                }}
                            >
                                {errors.otherSelectedRole}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        )
    }

    const renderStepTwo = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {shuffledSecondStep.map((goal) => (
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                        <Checkbox
                            checked={selectedGoals.includes(goal)}
                            onClick={() => {
                                if (selectedGoals.includes(goal)) {
                                    setSelectedGoals(selectedGoals.filter((g) => g !== goal))
                                } else {
                                    setSelectedGoals([...selectedGoals, goal])
                                }
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: '#201024',
                                fontWeight: 400,
                                fontFamily: 'Inter',
                            }}
                        >
                            {goal}
                        </Typography>
                    </Box>
                ))}
                {selectedGoals.includes(SECOND_STEP[SECOND_STEP.length - 1]) && (
                    <Box sx={{ width: '70%' }}>
                        <Input
                            padding={'8px'}
                            value={otherSelectedGoal}
                            onChange={(event) => setOtherSelectedGoal(event.target.value)}
                            label={'Please specify your goal'}
                        />
                        {errors.otherSelectedGoal && (
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'red',
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                }}
                            >
                                {errors.otherSelectedGoal}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        )
    }

    const renderStepThree = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '70%' }}>
                    <Input
                        padding={'8px'}
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                        label={'Company Name'}
                        placeholder={'Your Company Name'}
                    />
                    {errors.companyName && (
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'red',
                                fontWeight: 400,
                                fontFamily: 'Inter',
                            }}
                        >
                            {errors.companyName}
                        </Typography>
                    )}
                    <Input
                        padding={'8px'}
                        value={companyWebsite}
                        onChange={(event) => setCompanyWebsite(event.target.value)}
                        label={'Company Website'}
                        placeholder={'https://www.yourcompany.com'}
                    />
                    {errors.companyWebsite && (
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'red',
                                fontWeight: 400,
                                fontFamily: 'Inter',
                            }}
                        >
                            {errors.companyWebsite}
                        </Typography>
                    )}
                </Box>
                <Typography
                    sx={{
                        fontSize: '13px',
                        color: '#979099',
                        fontWeight: 400,
                        fontFamily: 'Inter',
                        mt: 2,
                    }}
                >
                    We respect your privacy and use this information to enhance your experience.
                </Typography>
            </Box>
        )
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderStepOne()
            case 1:
                return renderStepTwo()
            case 2:
                return renderStepThree()
            default:
                return null
        }
    }

    const checkIfRedirect = (createDate) => {
        const fiveMinutesInMilliseconds = 5 * 60 * 1000
        const now = Date.now()

        const sfDate = new Date(createDate)

        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/Los_Angeles',
            timeZoneName: 'short',
        })

        const timeZoneName = formatter
            .formatToParts(sfDate)
            .find((part) => part.type === 'timeZoneName').value

        const sfOffsetHours = timeZoneName === 'PST' ? -8 : -7
        const sfOffsetMilliseconds = sfOffsetHours * 60 * 60 * 1000

        const createDateUTC = createDate - sfOffsetMilliseconds

        return now - createDateUTC > fiveMinutesInMilliseconds
    }

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                maxWidth: '100vw',
                maxHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '65%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                        height: '100%',
                        padding: '40px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', flex: 1 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ width: '200px', height: 'auto' }}>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                    }}
                                    src={ScoopBlack}
                                    alt={'Scoop Black'}
                                />
                            </Box>
                            <Box>
                                <Typography>Step {step + 1} of 3</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    color: '#000',
                                    fontWeight: 600,
                                    fontFamily: 'Inter',
                                }}
                            >
                                {TITLES[step].title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: '#635566',
                                    fontWeight: 400,
                                    fontFamily: 'Inter',
                                }}
                            >
                                {TITLES[step].description}
                            </Typography>
                        </Box>
                        {renderStep()}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                color: '#979099',
                                fontSize: '14px',
                            }}
                            onClick={() => setStep(step - 1)}
                        >
                            {step === 0 ? '' : '< Back'}
                        </Typography>
                        <Button
                            disabled={!isNextEnabled() || isSubmitting}
                            className={'primary-button button-purple small'}
                            onClick={handleNext}
                        >
                            {step === 2 ? isSubmitting ? <ScoopLoader /> : 'Finish' : 'Next >'}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '35%',
                    height: '100%',
                    backgroundColor: '#130417',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '32px',
                    padding: '32px',
                }}
            >
                <Box sx={{ width: '90%', height: 'auto' }}>
                    <img
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        src={QuestionnaireImg}
                        alt={'Image'}
                    />
                </Box>
            </Box>
        </Box>
    )
}
