import { useMutation, useQuery } from '@tanstack/react-query'
import { getUserID } from '../../../utils'
import {
    createWorkspace,
    deleteWorkspace,
    getUserWorkspaces,
    renameWorkspace,
    submitRequest,
} from './workspaceApi'
import { useUserInfo } from '../user/useUserInfo'
import { logEvent } from '../logs/api'

export const useWorkspaces = () => {
    const userID = getUserID()

    const { user } = useUserInfo()

    const { data: workspaces, refetch: getWorkspaces } = useQuery({
        queryKey: ['workspaces', userID],
        queryFn: () => getUserWorkspaces(userID),
    })

    const { mutate: create, isPending: isCreating } = useMutation({
        mutationFn: ({ workspaceName }) => createWorkspace(workspaceName, user.userName),
        onSuccess: (response, variables) => {
            logEvent('newWorkspace', {
                type: 'createNewWorkspace',
                workspace: {
                    name: variables.workspaceName,
                    workspaceID: response.workspaceID,
                },
            })
            getWorkspaces().then((w) => {
                variables.onSuccess(response)
            })
        },
    })

    const { mutate: rename, isPending: isRenaming } = useMutation({
        mutationFn: ({ workspaceName, workspaceID }) => renameWorkspace(workspaceName, workspaceID),
        onSuccess: (response, variables) => {
            getWorkspaces().then((w) => {
                variables.onSuccess(w)
            })
        },
    })

    const { mutate: remove, isPending: isRemoving } = useMutation({
        mutationFn: ({ workspaceID }) => deleteWorkspace(workspaceID),
        onSuccess: (response, variables) => {
            getWorkspaces().then((w) => {
                variables.onSuccess(w.data)
            })
        },
    })

    const { mutate: submit, isPending: isSubmittingRequest } = useMutation({
        mutationFn: ({ email, message }) => submitRequest(email, message),
        onSuccess: (response, variables) => {
            variables?.onSuccess()
        },
    })

    return {
        workspaces: workspaces || [],
        getWorkspaces,
        create,
        isCreating,
        rename,
        isRenaming,
        remove,
        isRemoving,
        submit,
        isSubmittingRequest,
    }
}
