import React, { useEffect, useState } from 'react'
import {
    Box,
    InputAdornment,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material'
import Dialog from '../../../common/Dialog/Dialog'
import Copy from '../../../../assets/icons/Copy.svg'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../../api/api'
import { SelectType } from './SelectType'
import { logEvent } from '../../../../api/hooks/logs/api'
import { setOnboardingChecklist } from '../../../../store/actions/uiActions'

export const EmailForward = ({ onClose, stepBack, setAlert, getMetadata }) => {
    const [emailGenerated, setEmailGenerated] = useState('')
    const [step, setStep] = useState(0)
    const [reportName, setReportName] = useState('')
    const [reportDescription, setReportDescription] = useState('')
    const [type, setType] = useState('snapshot')
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const { postData } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/inboxemailactions'
    )
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)
    const dispatch = useDispatch()

    const [basicConfiguration, setBasicConfiguration] = useState(null)

    useEffect(() => {
        if (step === 0) {
            setEmailGenerated(`${generateUUID()}@scoopup.report`)
        }
    }, [step])
    const generateUUID = () => {
        return 'xxxxxxxx-xxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0
            const v = c === 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    const generateInboxName = (emailGenerated) => {
        // Remove the exact substring "@scoopup.report"
        let withoutDomain = emailGenerated.replace(/@scoopup\.report/g, '')

        // Replace non-alphanumeric characters, excluding dashes, with an underscore
        let result = withoutDomain.replace(/[^a-zA-Z0-9\-]/g, '_')

        return result
    }

    const handleSaveBasicConfiguration = async (basicConfiguration) => {
        setBasicConfiguration(basicConfiguration)
        setStep(2)
    }

    /*const handleSetUpInbox= async () => {
        const action = {
            "action": "setupInboxForBot",
            inboxName: '',
            workspaceID: workspaceID,
            service: bot.service,
            userName: userID,
        }
        const result = await postData(action);
        return result;
    }*/
    const handleCreateDataset = async () => {
        setAlert({ message: 'Creating Dataset, please wait', severity: 'info' })
        setStep(0)
        const action = {
            action: 'createInbox',
            inboxName: generateInboxName(emailGenerated),
            label: reportName,
            description: reportDescription,
            workspaceID: workspaceID,
            keepOnlyCurrent: basicConfiguration.isMostRecent,
            incremental: basicConfiguration.isIncremental,
            enableMultipleSameDayLoads: basicConfiguration.isMultipleLoads,
            isTransactional: type.toLowerCase() === 'transactional',
            inboxRange: basicConfiguration.workbookRange,
            userName: userID,
            userID: userID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        logEvent('newReportInbox', {
            type: 'attemptNewEmailReportInbox',
            inbox: { name: reportName },
        })
        if (result.status === 'success') {
            setAlert({
                message: 'Dataset Created Successfully',
                severity: 'success',
            })
            getMetadata()
            logEvent('newReportInbox', {
                type: 'completeNewEmailReportInbox',
                inbox: {
                    name: reportName,
                    inboxID: result?.inboxID,
                },
            })
            if (onboardingChecklist === '0') dispatch(setOnboardingChecklist('1'))
        }
        onClose()
        return result
    }
    return (
        <>
            {step === 1 ? (
                <SelectType
                    handleContinue={handleSaveBasicConfiguration}
                    index={1}
                    setType={setType}
                    step={step}
                    setStep={setStep}
                    onClose={onClose}
                    continueButton={'Continue'}
                />
            ) : (
                <Dialog
                    style={{ minWidth: '500px' }}
                    title={'Dataset from Email'}
                    onClose={onClose}
                    open
                    actions={
                        <>
                            {step === 0 ? (
                                <>
                                    <Button className={'button-grey small'} onClick={stepBack}>
                                        Back
                                    </Button>
                                    <Button
                                        className={'button-purple small'}
                                        onClick={() => {
                                            setStep(1)
                                        }}
                                    >
                                        {' '}
                                        Let's go{' '}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        className={'button-grey small'}
                                        onClick={() => setStep(1)}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        className={'button-purple small'}
                                        onClick={() => {
                                            handleCreateDataset()
                                        }}
                                    >
                                        Create Dataset{' '}
                                    </Button>
                                </>
                            )}
                        </>
                    }
                >
                    {step === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '12px',
                            }}
                        >
                            <Typography sx={{ fontSize: '14px', color: '#635566' }}>
                                Scoop data from any email containing a data attachment.
                                <br />
                                Follow this step by step guide or{' '}
                                <a
                                    href="javascript:void(0);"
                                    style={{ color: '#BF0A46' }}
                                    onClick={() =>
                                        window.open('https://scoopanalytics.com/help', '_blank')
                                    }
                                >
                                    read more here
                                </a>
                            </Typography>

                            <Stepper orientation="vertical">
                                <Step active={true}>
                                    <StepLabel
                                        StepIconProps={{
                                            style: {
                                                color: '#F2F2F2',
                                            },
                                        }}
                                    >
                                        Tell us whether your data is transactional or a snapshot
                                    </StepLabel>
                                </Step>
                                <Step active={true}>
                                    <StepLabel
                                        StepIconProps={{
                                            style: {
                                                color: '#F2F2F2',
                                            },
                                        }}
                                    >
                                        Visit your inbox and find the email you want to Scoop data
                                        from
                                    </StepLabel>
                                </Step>
                                <Step active={true}>
                                    <StepLabel
                                        StepIconProps={{
                                            style: {
                                                color: '#F2F2F2',
                                            },
                                        }}
                                    >
                                        Forward that email to an inbox email address we'll provide
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            }}
                        >
                            <Input
                                label={'Name'}
                                placeholder={'My data file'}
                                value={reportName} // Controlled component
                                onChange={(e) => setReportName(e.target.value)} // Update state on change
                            />
                            <Input
                                label={'Description'}
                                placeholder={'Optional'}
                                value={reportDescription} // Controlled component
                                onChange={(e) => setReportDescription(e.target.value)} // Update state on change
                            ></Input>

                            <Typography
                                sx={{
                                    color: '#14092A',
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                Copy this email address and forward your email here once done
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    padding: ' 12px 16px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    background: '#F9F9F9',
                                    fontSize: '14px',
                                    marginTop: '-16px',
                                    fontWeight: 600,
                                }}
                                id="email-subject-textfield"
                            >
                                {emailGenerated}
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(emailGenerated)
                                        setAlert({
                                            message: 'Email copied to clipboard',
                                            severity: 'info',
                                        })
                                    }}
                                >
                                    <img src={Copy} alt={'copy'} style={{ width: '20px' }} />
                                </InputAdornment>
                            </Box>
                        </Box>
                    )}
                </Dialog>
            )}
        </>
    )
}
