import {
    Box,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material'
import './DatasetInsight.css'
import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StatusBubble } from '../../../common/StatusBubble/StatusBubble'
import IconButton from '@mui/material/IconButton'
import DatabaseBlack from '../../../../assets/icons/DatabaseBlack.svg'
import Trash from '../../../../assets/icons/Trash.svg'
import XCircle from '../../../../assets/icons/XCircle.svg'
import DeleteDialog from '../../../common/Dialog/DeleteDialog'
import { Server } from '../../../../api/Server'
import { useSelector } from 'react-redux'
import ScoopDrawer from './ScoopDrawer'
import { Toast } from '../../../common/Toast/Toast'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useEventLogItems } from '../../../../api/hooks/datasource/hook'

export const CustomTableCell = ({
    children,
    active,
    direction,
    setActive,
    setDirection,
    field,
    ...props
}) => {
    return (
        <TableCell
            sx={{
                fontWeight: 'normal', // adjust the weight as needed
                color: '#000', // text color
                pb: 0.75, // padding bottom
                pt: 0.75, // padding top
                backgroundColor: '#F2F2F2',
                border: '1px solid #F2F2F2',
            }}
            {...props}
        >
            <TableSortLabel
                active={active === field}
                direction={direction}
                sx={{
                    '&.Mui-active': {
                        color: '#000', // active label color
                    },
                    '&:hover': {
                        color: '#000', // hover label color
                    },
                    '& .MuiTableSortLabel-icon': {
                        color: '#000 !important', // arrow color
                    },
                }}
                IconComponent={ArrowDropDownIcon}
                onClick={() => {
                    if (active === field) {
                        setDirection(direction === 'desc' ? 'asc' : 'desc')
                    } else {
                        setActive(field)
                        setDirection('desc')
                    }
                }}
            >
                {children}
            </TableSortLabel>
        </TableCell>
    )
}
const matchS3key = (str1, str2) => {
    if (str1 && str2) {
        // Normalize the strings by replacing spaces with '+' and vice versa
        let normalizedStr1 = str1.replace(/ /g, '+')
        let normalizedStr2 = str2.replace(/ /g, '+')

        return normalizedStr1 === normalizedStr2
    }
    return false
}

export const ScoopHistory = ({
    ingestionLogs,
    refreshLogs,
    inboxID,
    tables,
    setOpenReprocess,
    processStatus,
    dataset,
}) => {
    const [active, setActive] = useState('date')
    const [direction, setDirection] = useState('desc')
    const [pending, setPending] = useState(-1)
    const [sortedLogs, setSortedLogs] = useState(ingestionLogs)
    const [deleteLog, setDeleteLog] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(null)
    const [alert, setAlert] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [curLog, setCurLog] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)

    const {
        eventLogItems: eventLogs,
        isGetSuccess,
        isLoading,
        deleteEventLogItem,
    } = useEventLogItems(inboxID)

    //PAGINATION MODEL
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const currentItems = sortedLogs?.slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage
    )

    useEffect(() => {
        if (Array.isArray(eventLogs) && eventLogs.length > 0 && !eventLogs[0].s3Key) {
            setErrorMessage(eventLogs[0].message)
        }
    }, [eventLogs])

    const handleDeleteLog = async (response) => {
        await refreshLogs()
        setAlert({
            message: response.result ?? 'Log deleted successfully',
            severity: 'success',
        })
        setDeleteLog(null)
        setIsDeleteLoading(false)
    }

    const sortData = (field) => {
        const sortedData = [...ingestionLogs]

        sortedData.sort((a, b) => {
            if (field === 'inferredDate') {
                if (Date(a[field]) < Date(b[field]) || !a[field]) {
                    return direction === 'desc' ? 1 : -1
                }
                if (Date(a[field]) > Date(b[field]) || !b[field]) {
                    return direction === 'desc' ? -1 : 1
                }
            }
            if (a[field] < b[field] || !a[field]) {
                return direction === 'desc' ? 1 : -1
            }
            if (a[field] > b[field] || !b[field]) {
                return direction === 'desc' ? -1 : 1
            }
            return 0
        })
        for (let i = 0; i < sortedData.length; i++) {
            let log = sortedData[i]
            let logDetail = eventLogs?.find((logDetail) => matchS3key(logDetail.s3Key, log.s3key))
            if (logDetail) {
                log.detail = logDetail
            }
        }

        return setSortedLogs(sortedData)
    }

    useEffect(() => {
        sortData(active)
    }, [active, direction, ingestionLogs, eventLogs])

    const handleDelete = () => {
        if (deleteLog) {
            setIsDeleteLoading(true)
            deleteEventLogItem({ deleteLog, onSuccess: handleDeleteLog })
        }
    }

    return (
        <>
            <Paper sx={{ maxHeight: '100%', overflow: 'auto' }}>
                {errorMessage && (
                    <Box sx={{ mb: 2 }}>
                        <Typography className={'inter'} sx={{ color: 'red' }}>
                            Error: {errorMessage}
                        </Typography>
                    </Box>
                )}
                {ingestionLogs?.length > 0 ? (
                    <Table sx={{ borderCollapse: 'separate', height: '100%' }}>
                        <TableHead sx={{ position: 'sticky', top: '0', zIndex: 999 }}>
                            <TableRow>
                                <CustomTableCell
                                    sx={{
                                        '&:first-of-type': {
                                            borderTopLeftRadius: '5px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                            border: '1px solid #F2F2F2',
                                            pb: 0.75,
                                            pt: 0.75,
                                            backgroundColor: '#F2F2F2',
                                        },
                                    }}
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    field={'inferredDate'}
                                >
                                    Date
                                </CustomTableCell>
                                <CustomTableCell
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    field={'rstiKey'}
                                >
                                    Status
                                </CustomTableCell>
                                <CustomTableCell
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    field={'inferredDate'}
                                    setDirection={setDirection}
                                >
                                    Table
                                </CustomTableCell>
                                <CustomTableCell
                                    sx={{
                                        '&:last-of-type': {
                                            borderTopRightRadius: '5px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                            border: '1px solid #F2F2F2',
                                            pb: 0.75,
                                            pt: 0.75,
                                            backgroundColor: '#F2F2F2',
                                        },
                                    }}
                                    field={'inferredDate'}
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    fi
                                >
                                    Details
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems?.map((log, index) => (
                                <TableRow key={log.id}>
                                    <TableCell sx={{ borderLeft: '1px solid #E6E4E6' }}>
                                        {log.inferredDate}
                                    </TableCell>
                                    <TableCell>
                                        <StatusBubble
                                            status={log.rstiKey ? 'Success' : 'Failed'}
                                            withLabel
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {
                                            tables?.find(
                                                (table) =>
                                                    table.reportSeriesTableID ===
                                                    log.reportSeriesTableID
                                            )?.tableName
                                        }
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderRight: '1px solid #E6E4E6',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span>{log.detail?.message}</span>
                                            <div>
                                                {pending === log.id ? (
                                                    <IconButton
                                                        aria-label="edit"
                                                        sx={{
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                            src={XCircle}
                                                            alt={'delete'}
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <IconButton
                                                            aria-label="edit"
                                                            sx={{
                                                                borderRadius: '5px',
                                                            }}
                                                            onClick={(e) => {
                                                                setOpenDrawer({
                                                                    ...eventLogs?.find(
                                                                        (logDetail) =>
                                                                            matchS3key(
                                                                                logDetail.s3Key,
                                                                                log.s3key
                                                                            )
                                                                    ),
                                                                    ...log,
                                                                    tableName: tables?.find(
                                                                        (table) =>
                                                                            table.reportSeriesTableID ===
                                                                            log.reportSeriesTableID
                                                                    )?.tableName,
                                                                    inboxID: inboxID,
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: '20px',
                                                                }}
                                                                src={DatabaseBlack}
                                                                alt={'details'}
                                                            />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{
                                                                borderRadius: '5px',
                                                            }}
                                                            onClick={() => setDeleteLog(log)}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: '20px',
                                                                }}
                                                                src={Trash}
                                                                alt={'delete'}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={sortedLogs?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={(event, newPage) => setCurrentPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10))
                                setCurrentPage(0)
                            }}
                        />
                    </Table>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isLoading ? (
                            <ScoopLoader size={72} />
                        ) : (
                            <Typography>No dataset load history</Typography>
                        )}
                    </Box>
                )}
            </Paper>
            <DeleteDialog
                isLoading={isDeleteLoading}
                open={deleteLog}
                close={() => setDeleteLog(false)}
                title={'Scoop Log'}
                handleDelete={handleDelete}
                handleCancel={() => setDeleteLog(null)}
                description={
                    'Are you sure you want to delete this Scoop log? You won’t be able to access the data in this log again.'
                }
            />
            <ScoopDrawer
                open={Boolean(openDrawer)}
                onClose={() => setOpenDrawer(null)}
                logDetails={openDrawer}
                dataset={dataset}
                setOpenReprocess={setOpenReprocess}
            />
            <Toast alert={alert} onClose={() => setAlert(null)}>
                {' '}
            </Toast>
        </>
    )
}
export default ScoopHistory
