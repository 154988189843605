import { useMutation } from '@tanstack/react-query'
import { createSurveyRequest } from './userSurveyApi'

export const useUserSurvey = () => {
    const { mutate, isPending } = useMutation({
        mutationFn: ({ role, goals, companyName, companyWebsite, userID }) =>
            createSurveyRequest({ role, goals, companyName, companyWebsite, userID }),
        onSuccess: (response, variables) => {
            variables.onSuccess(response)
        },
    })

    return {
        createSurvey: mutate,
        isSubmitting: isPending,
    }
}
