import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import { CustomInput } from './CustomInput'
import { DataGrid } from '@mui/x-data-grid'
import Grid from '@mui/material/Unstable_Grid2'
import { packFilter } from './Filter'
import dayjs from 'dayjs'
import { ScoopDatePicker } from './ScoopDatePicker'

export function FilterModal({
    sheetID,
    table,
    inputQuery,
    filterList,
    setFilterList,
    open,
    setOpen,
    selectedColumn,
    operator,
    setOperator,
    items,
    setCategoryValues,
    selectedItems,
    setSelectedItems,
    updatePreviewData,
    server,
}) {
    const [search, setSearch] = React.useState('')
    const [likeValue, setLikeValue] = React.useState('')
    const [selectedDate, setSelectedDate] = React.useState(null)

    function getTableColumn(table, columnName) {
        var column = null
        table.columns.forEach((col) => {
            if (col.columnName === columnName) {
                column = col
            }
        })
        return column
    }

    const handleClose = () => setOpen(false)

    function handleSave() {
        let filter = null
        let index = 0
        for (let f of filterList) {
            if (f.attributeName === selectedColumn) {
                filter = f
                break
            }
            index++
        }
        if (operator === 'IsNull' || operator === 'IsNotNull') {
            filter = {
                attributeName: selectedColumn,
                operator: operator,
            }
            filterList.push(filter)
        } else if (operator === 'Between') {
            let newFilter = {
                attributeName: selectedColumn,
                operator: 'GreaterThanOrEquals',
                filterValue: {
                    values: [selectedItems[0]],
                },
            }
            filterList.push(newFilter)
            newFilter = {
                attributeName: selectedColumn,
                operator: 'LessThanOrEquals',
                filterValue: {
                    values: [selectedItems[1]],
                },
            }
            filterList.push(newFilter)
        } else if (selectedItems.length === 0) {
            if (filter !== null) {
                filterList.splice(index, 1)
            }
        } else {
            if (filter == null) {
                filter = {
                    attributeName: selectedColumn,
                    operator: operator,
                    filterValue: {
                        values: selectedItems,
                    },
                }
                filterList.push(filter)
            } else {
                filter.operator = operator
                filter.filterValue = {
                    values: selectedItems,
                }
            }
        }
        inputQuery.filter = packFilter(filterList)
        setFilterList(filterList)
        updatePreviewData(inputQuery)
        setOpen(false)
    }

    function handleClear() {
        var newFilters = []
        for (var f of filterList) {
            if (f.attributeName !== selectedColumn) {
                newFilters.push(f)
            }
        }
        inputQuery.filter = packFilter(newFilters)
        setFilterList(newFilters)
        updatePreviewData(inputQuery)
        setOpen(false)
    }

    function handleSearchChange(event) {
        setSearch(event.target.value)
        server.sheetPostData(
            sheetID,
            {
                action: 'addOn',
                addOnAction: 'getCategoryValues',
                sheetID: sheetID,
                tableID: inputQuery.tableID,
                columnName: selectedColumn,
                like: event.target.value,
            },
            setCategoryValues
        )
    }

    function handleLikeChange(event) {
        setLikeValue(event.target.value)
    }

    function addLikeValue(event) {
        setSelectedItems([likeValue, ...selectedItems])
    }

    function removeLikeValue(event) {
        let result = []
        let found = false
        for (let i = 0; i < selectedItems.length; i++) {
            let val = selectedItems[i]
            if (event.target.name === val) {
                found = true
            } else {
                result.push(val)
            }
        }
        setSelectedItems(result)
    }

    const handleSelectDate = (newDate) => {
        let newSelectedItems = []
        if (operator === 'Between') {
            const from = dayjs(newDate[0]).format('YYYY-MM-DD')
            const to = dayjs(newDate[1]).format('YYYY-MM-DD')
            newSelectedItems.push(from, to)
        } else {
            newSelectedItems.push(dayjs(newDate).format('YYYY-MM-DD'))
        }
        setSelectedItems(newSelectedItems)
        setSelectedDate(newDate)
    }

    // Make sure all selected items are in the list at the top
    let newItems = []
    let newSelectedItems = []
    for (let i = 0; i < selectedItems.length; i++) {
        newSelectedItems.push({ id: selectedItems[i], value: selectedItems[i] })
    }
    for (let j = 0; j < items.length; j++) {
        let found = false
        for (let i = 0; i < selectedItems.length; i++) {
            if (items[j].value === selectedItems[i]) {
                found = true
                break
            }
        }
        if (!found) {
            newItems.push(items[j])
        }
    }
    items = [...newSelectedItems, ...newItems]

    if (!selectedColumn) return null
    var column = getTableColumn(table, selectedColumn)
    var isNumeric =
        column != null &&
        (column.columnType === 'Decimal' ||
            column.columnType === 'Currency' ||
            column.columnType === 'Integer')
    var isDate = column != null && column.columnType === 'DateTime'
    return (
        <>
            <Modal
                sx={{
                    display: 'flex',
                    p: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: 800,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: (theme) => theme.shadows[5],
                        p: 4,
                    }}
                >
                    <AppBar key="bar" position="static" sx={{ bgcolor: '#88458d' }}>
                        <Toolbar>
                            <Box width="100%"></Box>
                            <Button variant="contained" sx={{ ml: 1, mr: 1 }} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" sx={{ ml: 1, mr: 1 }} onClick={handleClear}>
                                Clear
                            </Button>
                            <Button variant="contained" sx={{ ml: 1, mr: 1 }} onClick={handleSave}>
                                Save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Typography variant="h6" component="h2" sx={{ mt: 1, mb: 1 }}>
                        {selectedColumn}
                    </Typography>
                    <Box>
                        <Select
                            sx={{ height: 32 }}
                            value={operator}
                            onChange={(event) => {
                                setOperator(event.target.value)
                            }}
                        >
                            <MenuItem value={'Equals'}>Equals</MenuItem>
                            {(isNumeric || isDate) && (
                                <MenuItem value={'GreaterThan'}>Greater Than</MenuItem>
                            )}
                            {(isNumeric || isDate) && (
                                <MenuItem value={'GreaterThanOrEquals'}>
                                    Greater Than or Equals
                                </MenuItem>
                            )}
                            {(isNumeric || isDate) && (
                                <MenuItem value={'LessThan'}>Less Than</MenuItem>
                            )}
                            {(isNumeric || isDate) && (
                                <MenuItem value={'LessThanOrEquals'}>Less Than or Equals</MenuItem>
                            )}
                            <MenuItem value={'NotEquals'}>Not Equals</MenuItem>
                            {(isNumeric || isDate) && (
                                <MenuItem value={'Between'}>Between</MenuItem>
                            )}
                            {!isNumeric && !isDate && <MenuItem value={'Like'}>Like</MenuItem>}
                            {!isNumeric && !isDate && <MenuItem value={'IsNull'}>Is Null</MenuItem>}
                            {!isNumeric && !isDate && (
                                <MenuItem value={'IsNotNull'}>Is Not Null</MenuItem>
                            )}
                        </Select>
                    </Box>
                    {(operator === 'Equals' || operator === 'NotEquals') &&
                        (column === null || !column.isMeasure) && (
                            <Box>
                                {(items.length > 0 || search.length > 0) && (
                                    <Box>
                                        <Box>
                                            <Typography sx={{ mr: 15 }}>Search: </Typography>
                                            <CustomInput
                                                name="search"
                                                value={search}
                                                onChange={handleSearchChange}
                                            />
                                        </Box>
                                        <DataGrid
                                            columns={[
                                                {
                                                    field: 'value',
                                                    headerName: 'Value',
                                                    type: 'string',
                                                    sortable: false,
                                                    editable: false,
                                                    width: '100%',
                                                    headerAlign: 'center',
                                                },
                                            ]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        page: 0,
                                                        pageSize: 20,
                                                    },
                                                },
                                            }}
                                            rows={items}
                                            rowHeight={25}
                                            checkboxSelection
                                            rowSelectionModel={selectedItems}
                                            onRowSelectionModelChange={(ids) => {
                                                setSelectedItems(ids)
                                            }}
                                        />
                                    </Box>
                                )}
                                {items.length === 0 && search.length === 0 && (
                                    <Typography variant="body1">
                                        Column appears empty - no items available to select
                                    </Typography>
                                )}
                            </Box>
                        )}
                    {(operator === 'Equals' || operator === 'NotEquals') &&
                        column !== null &&
                        column.isMeasure && (
                            <Box>
                                <Typography sx={{ mr: 10 }}>Value:</Typography>
                                <CustomInput
                                    sx={{ width: 200, mr: 10 }}
                                    value={selectedItems[0]}
                                    onChange={(event) => setSelectedItems([event.target.value])}
                                />
                            </Box>
                        )}
                    {(operator === 'GreaterThan' ||
                        operator === 'LessThan' ||
                        operator === 'LessThanOrEquals' ||
                        operator === 'GreaterThanOrEquals' ||
                        operator === 'GreaterThanOrEquals' ||
                        operator === 'Between') && (
                        <Box sx={{ mb: 3, width: '50%' }}>
                            <Typography sx={{ mr: 10 }}>Value:</Typography>
                            {isDate ? (
                                <ScoopDatePicker
                                    value={selectedDate}
                                    onChange={(newValue) => handleSelectDate(newValue)}
                                    range={operator === 'Between'}
                                />
                            ) : (
                                <CustomInput
                                    type={isNumeric ? 'number' : 'text'}
                                    sx={{
                                        width: 200,
                                        mr: 10,
                                    }}
                                    value={selectedItems[0]}
                                    onChange={(event) => setSelectedItems([event.target.value])}
                                />
                            )}
                        </Box>
                    )}
                    {operator === 'Like' && (
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                m: 0,
                                width: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid item xs={2}>
                                <Typography>Value:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomInput
                                    style={{ width: 200, mr: 10 }}
                                    value={likeValue}
                                    onChange={handleLikeChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="text" sx={{ ml: 0, mr: 0 }} onClick={addLikeValue}>
                                    Add Value
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {operator === 'Like' &&
                        selectedItems.map((item) => (
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    m: 0,
                                    width: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Grid item xs={6}>
                                    <Typography sx={{ m: 1 }}>{item}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="text" name={item} onClick={removeLikeValue}>
                                        remove
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                </Box>
            </Modal>
        </>
    )
}
