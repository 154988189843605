import dayjs from 'dayjs'
import { packFilter } from './Filter'
import { getFormatter } from './KPI/utils'
import { SORTING } from './consts'
import _ from 'lodash'
import { DEFAULT_CHART_PREFERENCES } from '../screens/Explorer/DrawerTabs/Style/styleConsts'
import { backwardsFlag } from './ChartState'

export function parseMetric(item, selectedDates) {
    let metric

    if (item.worksheetID) {
        metric = item
    } else if (item.kpi) {
        metric = {
            metricName: item.kpi,
            reportSeriesTableID: item.reportSeriesTableID,
        }
    } else {
        metric = {
            measureName: item.columnName,
            reportSeriesTableID: item.reportSeriesTableID,
        }
    }

    if (selectedDates) {
        const key = item.worksheetID
            ? `${item.worksheetID}:${item.measureName}`
            : `${item.reportSeriesTableID}:${item.columnName}`
        const byDate = selectedDates.get(key)
        if (byDate) metric.byDate = byDate
    }

    return metric
}

export function processTimeRange(timeRange, config) {
    const timeRangeAction = { timeRange }

    if (timeRange === 'Custom') {
        if (config.fromPeriod) timeRangeAction.fromPeriod = config.fromPeriod
        if (config.toPeriod) timeRangeAction.toPeriod = config.toPeriod

        if (!config.fromPeriod && !config.toPeriod) {
            if (config.from) {
                timeRangeAction.start = dayjs(config.from).format('MM/DD/YYYY')
            }
            if (config.to) {
                timeRangeAction.end = dayjs(config.to).format('MM/DD/YYYY')
            }
        }
    }

    return timeRangeAction
}

export function processPrompts(prompts) {
    const validPrompts = []

    prompts.forEach((prompt) => {
        if (Array.isArray(prompt)) {
            validPrompts.push(
                ...prompt.filter(
                    (p) => p.filterValue.values.length > 0 && p.filterValue.values[0] !== 'All'
                )
            )
        } else if (prompt.filterValue.values.length > 0 && prompt.filterValue.values[0] !== 'All') {
            validPrompts.push(prompt)
        }
    })

    return packFilter(validPrompts)
}

export function combineFilters(filter, savedFilter) {
    if (filter || savedFilter) {
        return savedFilter ? combineFilters(filter, savedFilter.filter) : filter
    }
    return undefined
}

export function processNonScatter(sType, result) {
    return result.series.map((series, i) => {
        const metric = result.metrics.find((m) => m.name === series.metric)
        const categoryIndex = result.categories?.length === 1 ? i : series.categoryIndex
        const category = this.getCategory(result, series)

        const data = result.categoryAxisValues
            ? this.getCategoryAxisData(series)
            : this.getTimeSeriesData(result, series)

        return {
            type: sType,
            metric: series.metric,
            categoryIndex,
            category,
            name: series.name,
            format: metric?.format || null,
            data,
            ...(sType === 'bar' ? { xAxisIndex: series.axis } : { yAxisIndex: series.axis }),
        }
    })
}

export function processScatter(sType, result) {
    if (result.metrics.length < 2) return []

    return result.categories?.map((_, catIndex) => {
        const categoryName = this.getCategory(result, { categoryIndex: catIndex })
        const pairedSeries = this.getPairedScatterSeries(result, catIndex)

        const data =
            pairedSeries.length === 2
                ? this.pairScatterData(pairedSeries)
                : this.fillMissingScatterData(pairedSeries)

        return {
            type: sType,
            categoryIndex: catIndex,
            name: categoryName,
            data,
            formats: pairedSeries.map((ps) => ps.metric.format || ''),
        }
    })
}

export function getCategory(result, series) {
    return result.categories?.length === 1 && result.categories[0] === 'All'
        ? null
        : result.categories[series.categoryIndex]
}

export function getCategoryAxisData(series) {
    return series.data.map((dataPoint) => dataPoint)
}

export function getTimeSeriesData(result, series) {
    return result.dates?.map((date, index) => [date, series.data[index]])
}

export function getPairedScatterSeries(result, categoryIndex) {
    return result.metrics
        .slice(0, 2)
        .map((metric) =>
            result.series.find(
                (series) => series.metric === metric.name && series.categoryIndex === categoryIndex
            )
        )
        .filter(Boolean)
}

export function pairScatterData(pairedSeries) {
    const [seriesX, seriesY] = pairedSeries
    return seriesX.data.map((x, index) => [x, seriesY.data[index]])
}

export function fillMissingScatterData(pairedSeries) {
    const series = pairedSeries[0] || pairedSeries[1]
    return series.data.map((dataPoint) => (pairedSeries[0] ? [dataPoint, 0] : [0, dataPoint]))
}

export function createAxes(sType, axes = []) {
    return axes.map((axis, i) => {
        const position = this.getAxisPosition(sType, axis.name, i)
        return {
            name: axis.name ? this.axisNameFormatter(axis.name) : undefined,
            type: 'value',
            alignTicks: true,
            axisLabel: {
                formatter: (val) => getFormatter(axis.format).format(val),
                align: this.getAxisLabelAlignment(sType, position),
            },
            position,
            offset: i > 1 ? 80 * (i - 1) : undefined,
            nameTextStyle: this.getNameTextStyle(sType, axis.name, position),
        }
    })
}

export function getAxisPosition(sType, axisName, index) {
    if (this.config.metricsOrder.length > 0) {
        const matchedMetric = this.config.metricsOrder.find((m) => m.name.includes(axisName)) || {}
        return matchedMetric.position || (index > 0 ? 'right' : 'left')
    }
    return sType === 'bar' ? (index > 0 ? 'bottom' : 'top') : index > 0 ? 'right' : 'left'
}

export function getAxisLabelAlignment(sType, position) {
    return sType === 'bar'
        ? position === 'bottom'
            ? 'top'
            : 'bottom'
        : position === 'right'
          ? 'left'
          : 'right'
}

export function getNameTextStyle(sType, axisName, position) {
    const padding = calculatePadding(axisName)
    return sType === 'bar'
        ? undefined
        : position === 'right'
          ? { padding: [0, padding, 0, 0] }
          : { padding: [0, 0, 0, padding] }
}

export function updateConfigAndAttributes(sType, result, newSeries, newAxes) {
    this.series = newSeries
    this.valueAxis = newAxes
    this.drillAttributes = result.drillAttributes
    this.changeDrillAttributes = result.changeDrillAttributes
    this.categories = result.categories
    this.config = {
        ...this.config,
        seriesType: sType,
        categoryAxis: result.categoryAxis,
        allowedKPIs: result.allowedKPIs,
        allowedReportSeriesTables: result.allowedReportSeriesTables,
    }
    if (newSeries?.length > 0) {
        this.configureSeries(sType, this.config)
    }
}

export function getDefaultConfig() {
    return {
        chartTitle: '',
        selectedItems: [],
        selectedDates: new Map(),
        seriesType: 'column',
        stacked: true,
        seriesTypeMap: new Map(),
        categoryAxis: 'Time',
        sortColumns: [
            {
                column: '',
                order: SORTING.NAT,
            },
        ],
        period: 'Daily',
        timeRange: 'Last Year',
        calendarType: 'Rolling',
        drillAttribute: undefined,
        worksheetColumns: [],
        selectedTableColumns: [],
        nonPivotColumnsOrder: [],
        pivotColumnsOrder: [],
        selectedTableKpis: [],
        usedDrillAttributes: [],
        from: null,
        to: null,
        filter: null,
        savedFilter: null,
        view: 'chart',
        loading: false,
        worksheetID: undefined,
        rangeName: undefined,
        sheetName: undefined,
        selectedWorksheetName: undefined,
        activePrompts: [],
        tableState: {
            nonPivotState: { rowGroupCols: [] },
            pivotState: { rowGroupCols: [], pivotCols: [] },
            pinnedCols: [],
        },
        selectedTables: [],
        displayRows: 'All',
        tableTimeSeries: true,
        disableTimeSeries: false,
        allowedKPIs: undefined,
        allowedReportSeriesTables: undefined,
        styleOverrides: _.cloneDeep(DEFAULT_CHART_PREFERENCES),
        themeID: undefined,
        kpiCompareType: 'percentage',
        kpiCompareTarget: undefined,
        metricsOrder: [],
        backwardsFlag: backwardsFlag,
    }
}

export const getWorkspaceTheme = (themeID, workspaceMetadata) => {
    let th = undefined
    workspaceMetadata?.themes.forEach((t) => {
        if (t.themeID === themeID) th = t
    })
    return th
}

const calculatePadding = (inputString) => {
    const textSize = 4
    const minLength = 20
    if (!inputString || inputString.length < minLength) return 0
    return (inputString.length - minLength) * textSize
}
