import { useState } from 'react'
import { confirmSignUp, login, signUp } from '../../../../api/auth'
import { useDispatch } from 'react-redux'
import { setAuthenticated } from '../../../../store/actions/authActions'
import Cookies from 'js-cookie'
import { logEvent } from '../../../../api/hooks/logs/api'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const useSignUp = (step, setStep, setShowSnackbar, setErrors) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [signUpUser, setSignUpUser] = useState({
        fullName: '',
        username: '',
        email: '',
        password: '',
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSignUpInputChange = (fieldName, valueOrEvent) => {
        // Check if the input is from an event (likely from a checkbox)
        const isEvent = valueOrEvent && typeof valueOrEvent === 'object' && valueOrEvent.target

        if (isEvent) {
            const { target } = valueOrEvent
            const value = target.type === 'checkbox' ? target.checked : target.value
            setSignUpUser((prevState) => ({
                ...prevState,
                [fieldName]: value,
            }))
        } else {
            // This handles cases where the value is passed directly
            setSignUpUser((prevState) => ({
                ...prevState,
                [fieldName]: valueOrEvent,
            }))
        }
    }

    const validateSignUp = () => {
        let isValid = true
        if (signUpUser.fullName.length < 3) {
            setErrors((prevState) => ({
                ...prevState,
                fullName: 'Please enter your full name',
            }))
            isValid = false
        } else {
            setErrors((prevState) => ({
                ...prevState,
                fullName: '',
            }))
        }
        if (!signUpUser.email || !emailRegex.test(signUpUser.email)) {
            setErrors((prevState) => ({
                ...prevState,
                email: 'Please enter a valid business email',
            }))
            isValid = false
        } else {
            setErrors((prevState) => ({
                ...prevState,
                email: '',
            }))
        }
        if (!signUpUser.password || !passwordRegex.test(signUpUser.password)) {
            setErrors((prevState) => ({
                ...prevState,
                password:
                    'Please enter a password that is 8 characters long, contains both an uppercase and lowercase letter, a number, and a special character',
            }))
            isValid = false
        } else {
            setErrors((prevState) => ({
                ...prevState,
                password: '',
            }))
        }
        if (!signUpUser.agreeCheckbox) {
            setErrors((prevState) => ({
                ...prevState,
                agreeCheckbox: 'Please check to continue.',
            }))
            isValid = false
        }
        return isValid
    }

    const getHubspotUTK = () => {
        const cookie = Cookies.get('hubspotutk')
        return cookie ? cookie : ''
    }

    const handleSignUp = () => {
        signUpUser.username = generateUUID()
        logEvent('SignUpAttempt', [{ title: `Attempting new signup as ${signUpUser.email} ` }])
        // try {
        //     window.dataLayer.push({ 'event':'email_verified' })
        // } catch (e) {}
        if (validateSignUp()) {
            setIsLoading(true)
            signUp({
                username: signUpUser.username,
                email: signUpUser.email,
                password: signUpUser.password,
            })
                .then(() => {
                    setStep(step + 1)
                })
                .catch((error) => {
                    setShowSnackbar(error.message, 'error')
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleConfirmSignup = (verificationCode) => {
        setIsLoading(true)
        confirmSignUp({
            username: signUpUser.username,
            code: verificationCode,
        })
            .then(async (res) => {
                logEvent('SignUpAccountConfirmed', [
                    {
                        title: `User ${signUpUser.email} confirmed signup account, now provisioning. `,
                    },
                ])
                setShowSnackbar({
                    msg: 'Account confirmed! Please wait a few moment while we provision a space for you.',
                    severity: 'success',
                })
                // TO-DO: uncomment this!!!!
                await provisionNewUser()
            })
            .catch((error) => {
                setShowSnackbar({ msg: error.message, severity: 'error' })
            })
            .finally(() => {
                setIsLoading(false)
                try {
                    window.dataLayer.push({ event: 'signup_confirmed' })
                } catch (e) {}
            })
    }

    const getFirstName = () => {
        return signUpUser.fullName.split(' ')[0]
    }

    const getLastName = () => {
        return signUpUser.fullName.split(' ').slice(1).join(' ')
    }

    const provisionNewUser = async () => {
        setIsLoading(true)
        const body = {
            userName: signUpUser.email,
            userID: signUpUser.username,
            firstName: getFirstName(),
            lastName: getLastName(),
            hubspotUTK: getHubspotUTK(),
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        try {
            const response = await fetch(
                'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/provisionnewuser',
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(body),
                }
            )
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
        } catch (error) {
            console.error('There was an error with the fetch call:', error)
        } finally {
            setIsLoading(false)
            handleGoToCanvas()
        }
    }

    const handleGoToCanvas = () => {
        setIsLoading(true)
        login({
            username: signUpUser.email,
            password: signUpUser.password,
        })
            .then((res) => {
                dispatch(setAuthenticated(true, res))
                navigate(ROUTES.ONBOARDING)
            })
            .catch((error) => {
                setShowSnackbar({ msg: error.message, severity: 'error' })
            })
            .finally(() => {
                setIsLoading(false)
                setShowSnackbar({
                    msg: 'Loading new Workspace...',
                    severity: 'success',
                })
            })
    }

    return {
        signUpUser,
        handleSignUpInputChange,
        handleSignUp,
        isSignUpLoading: isLoading,
        handleConfirmSignup,
        handleGoToCanvas,
    }
}

export default useSignUp
