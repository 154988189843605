import { mobileAPIRequest } from '../../api'

export const getWorkspaceMetadata = async (tableStats) => {
    const action = {
        action: 'getWorkspaceMetadata',
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }
    if (tableStats) action.tableStats = true

    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const saveNewMetric = async (metric) => {
    const action = {
        action: 'putObject',
        class: 'scoop.metric.Metric',
        value: metric,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const deleteMetric = async (metricID) => {
    const action = {
        action: 'deleteObject',
        class: 'scoop.metric.Metric',
        key: metricID,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const addSavedFilter = async (workspaceID, filterName, filter, filterKey) => {
    const action = {
        action: 'putObject',
        class: 'scoop.queryfilter.SavedFilter',
        value: {
            workspaceID,
            filterName,
            filter,
            savedFilterKey: filterKey || null,
        },
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const deleteSavedFilter = async (savedFilterKey) => {
    const action = {
        action: 'delete',
        savedFilterKey: savedFilterKey,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const saveNewTheme = async (themeName, canvasID, chartPreferences, colorScheme, fonts) => {
    const getAction = {
        action: 'getNewID',
        class: 'scoop.presentation.Theme',
    }
    const response = await mobileAPIRequest({
        method: 'post',
        data: getAction,
    })
    const newThemeID = response.data
    const saveAction = {
        action: 'putObject',
        class: 'scoop.presentation.Theme',
        value: {
            themeID: newThemeID,
            themeName,
            canvasID,
            chartPreferences,
            colorScheme,
            fonts,
        },
    }
    return await mobileAPIRequest({
        method: 'post',
        data: saveAction,
    })
}

export const deleteInbox = async (inboxID) => {
    const action = {
        action: 'delete',
        inboxID,
        deleteAll: true,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const validateMetricFormula = async (formula, format) => {
    const action = {
        action: 'validateFormula',
        formula: formula,
        format: format,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
