import { SelectSourceStep } from './SelectSourceStep'
import { useEffect, useState } from 'react'
import FileUpload from '../../../Objects/FileUpload'
import { ConnectDatasource } from './ConnectDatasource'
import { EmailForward } from './EmailForward'
import BlendingDatasets from './Blending/BlendingDatasets'
import { logEvent } from '../../../../api/hooks/logs/api'
import { CreateDatasetModal } from './CreateDatasetModal'

export const NewDatasetModal = ({ open, onClose, setAlert, getMetadata, openBlendedDataset }) => {
    const [step, setStep] = useState(0)
    const [selectedSource, setSelectedSource] = useState('')
    const [option, setOption] = useState('')

    const handleSelectSource = (option, optionalSource) => {
        setStep(1)
        setOption(option)
        if (optionalSource) setSelectedSource(optionalSource)
    }

    useEffect(() => {
        if (openBlendedDataset) {
            setStep(1)
            setOption('Existing Scoop Data')
        }
    }, [openBlendedDataset])

    const renderStepContent = () => {
        if (open)
            switch (step) {
                case 0:
                    return (
                        <CreateDatasetModal
                            open={true}
                            handleSelectSource={handleSelectSource}
                            close={onClose}
                            setAlert={setAlert}
                        />
                    )
                case 1:
                    return renderStep2()
                default:
                    return renderStepContent()
            }
        else return <></>
    }
    const renderStep2 = () => {
        if (open) {
            switch (option) {
                case 'Data File':
                    return (
                        <FileUpload
                            isOpen={open}
                            onClose={(inboxName) => {
                                onClose()
                                getMetadata().then((res) => {
                                    const inboxID = res.data.inboxes.find(
                                        (inbox) => inbox.label === inboxName
                                    )?.inboxID
                                    logEvent('uploadData', {
                                        type: 'completeUploadData',
                                        file: { name: inboxName },
                                        inbox: { inboxID: inboxID },
                                    })
                                })
                                setStep(0)
                            }}
                            stepBack={() => setStep(0)}
                        />
                    )
                case 'Application':
                    return (
                        <ConnectDatasource
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => {
                                console.log('step back')
                                setStep(0)
                            }}
                            setAlert={setAlert}
                            source={selectedSource.sourceValue}
                            sourceStep={selectedSource.step}
                        />
                    )
                case 'Data Email':
                    return (
                        <EmailForward
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => setStep(0)}
                            setAlert={setAlert}
                            getMetadata={getMetadata}
                        />
                    )
                case 'Existing Scoop Data':
                    return (
                        <BlendingDatasets
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => onClose()}
                        />
                    )
                default:
                    return <> </>
            }
        }
    }
    return <>{renderStepContent()}</>
}
