import Dialog from '../../../common/Dialog/Dialog'
import { Box, Typography } from '@mui/material'
import { TypeBox } from './TypeBox'
import { useEffect, useState } from 'react'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'

export const SelectAPIorReport = ({
    setStep,
    onClose,
    setType,
    isLoading,
    handleContinue,
    sourceName,
    stepBack,
}) => {
    const [active, setActive] = useState('API Data Source')

    return (
        <Dialog
            open
            onClose={() => {
                onClose()
                setStep(0)
            }}
            actions={
                <>
                    <Button
                        className={'button-grey small'}
                        onClick={() => {
                            setType(active)
                            setStep(0)
                            stepBack()
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!active || isLoading}
                        className={'button-purple small'}
                        onClick={() => {
                            setType(active)
                            handleContinue(active)
                        }}
                    >
                        Continue
                    </Button>
                </>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                }}
            >
                <Typography sx={{ color: '#201024', fontSize: '24px', fontWeight: 600 }}>
                    Create New Dataset
                </Typography>
                <Typography
                    sx={{
                        display: 'inline',
                        textAlign: 'center',
                        width: '75%',
                        color: '#635566',
                    }}
                >
                    Select the method you would like to use to scoop {sourceName} data.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <TypeBox
                        title={'Report Data Source'}
                        description={
                            'Utilize a ' +
                            sourceName +
                            ' report for your data source. This leverages our report "Scooping" technology where you can define your report in Hubspot, and Scoop will read it.'
                        }
                        active={active}
                        setActive={setActive}
                        enabled={true}
                    ></TypeBox>
                    <TypeBox
                        title={'API Data Source'}
                        description={
                            'This leverages lower level data APIs. Here you can pull data from a single ' +
                            sourceName +
                            ' object with some simple filtering options. '
                        }
                        active={active}
                        setActive={setActive}
                        enabled={true}
                    ></TypeBox>
                </Box>
                {isLoading && <ScoopLoader size={72} />}
            </Box>
        </Dialog>
    )
}
