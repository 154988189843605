import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import * as React from 'react'
import { useSelector } from 'react-redux'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import Input from '../common/Input/Input'
import { useState } from 'react'
import { useApi } from '../../api/api'
import { InsightClass } from '../Objects/Insight/InsightClass'
import { useCanvases } from '../../api/hooks/canvases/useCanvases'
import { useInsights } from '../../api/hooks/insights/useInsights'

export function SaveDialog({ open, setOpen, insight, setInsight, workspaceMetadata, server }) {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const { postData: postCanvasData } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2'
    )
    const [saveToCanvas, setSaveToCanvas] = useState('')

    const { canvases } = useCanvases()
    const { createInsight, refetch } = useInsights()

    const handleClose = () => {
        setOpen(false)
    }

    const handleSave = () => {
        setOpen(false)
        createInsight({
            insightName: insight.insightName,
            savedInsight: insight.savedObject,
            workspaceID: insight.workspaceID,
            onSuccess: async () => {
                const result = await refetch()
                if (saveToCanvas) {
                    const savedInsight = result.data.objects.filter(
                        (i) => i.insightName === insight.insightName
                    )[0]
                    const action = {
                        action: 'loadCanvas',
                        userID: userID,
                        workspaceID: workspaceID,
                        canvasID: saveToCanvas.canvasID,
                        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                    }
                    const resultCanvas = await postCanvasData(action)
                    if (resultCanvas) {
                        const newInsight = InsightClass.newInsight(
                            savedInsight.insightName,
                            {
                                insightKey: savedInsight.insightKey,
                                workspaceID: savedInsight.workspaceID,
                            },
                            parseFloat(resultCanvas.zoom) || 1
                        )
                        if (resultCanvas?.canvasObjects?.length > 0) {
                            const action = {
                                action: 'saveCanvas',
                                userID: userID,
                                workspaceID: workspaceID,
                                canvasID: saveToCanvas.canvasID,
                                canvasName: resultCanvas.canvasName || '',
                                canvasObjects: [
                                    ...JSON.parse(resultCanvas.canvasObjects),
                                    newInsight,
                                ],
                                canvasImage: null,
                                zoom: resultCanvas.zoom,
                                presentationID: resultCanvas.presentationID || '',
                                lastSaved: resultCanvas.lastSaved,
                                background: resultCanvas.background || '#FFFFFF',
                                isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                            }
                            await postCanvasData(action)
                        } else {
                            const action = {
                                action: 'saveCanvas',
                                userID: userID,
                                workspaceID: workspaceID,
                                canvasID: saveToCanvas.canvasID,
                                canvasName: resultCanvas.canvasName || '',
                                canvasObjects: [newInsight],
                                canvasImage: null,
                                zoom: resultCanvas.zoom,
                                presentationID: resultCanvas.presentationID || '',
                                lastSaved: resultCanvas.lastSaved,
                                background: resultCanvas.background || '#FFFFFF',
                                isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                            }
                            await postCanvasData(action)
                        }
                    }
                }
            },
        })
    }

    const actions = (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Button onClick={handleClose} text={'Cancel'} className={'primary-button'} />
            <Button
                onClick={handleSave}
                text={'Save'}
                className={'primary-button button-purple'}
                disabled={!insight?.insightName}
            />
        </Box>
    )

    if (workspaceMetadata && insight) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'800px'}
                title={'Save Insight'}
                actions={actions}
            >
                <Box
                    sx={{
                        width: 500,
                        '& .MuiFormControl-root': {
                            marginTop: '0 !important',
                        },
                        height: `${canvases.length === 0 ? '64px !important' : ''}`,
                    }}
                >
                    <Input
                        id={'insightName'}
                        placeholder={'Insight name'}
                        fullWidth
                        defaultValue={insight.insightName}
                        onChange={(event) => {
                            let newInsight = { ...insight }
                            newInsight.insightName = event.target.value
                            setInsight(newInsight)
                        }}
                        style={{ marginBottom: '16px' }}
                        padding={'8px'}
                    />
                    <FormControl fullWidth>
                        {canvases.length > 0 && (
                            <>
                                <InputLabel>Add insight to canvas</InputLabel>
                                <Select
                                    value={saveToCanvas}
                                    onChange={(event) => setSaveToCanvas(event.target.value)}
                                    label="Add insight to canvas"
                                >
                                    {canvases.map((canvas) => (
                                        <MenuItem key={canvas.canvasName} value={canvas}>
                                            {canvas.canvasName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>
                </Box>
            </Dialog>
        )
    } else return null
}
