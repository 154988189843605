// uiReducer.js
import {
    TOGGLE_INSIGHT_MODAL,
    TOGGLE_SOURCE_MODAL,
    SET_RESET_TRIGGER,
    TOGGLE_FILE_UPLOAD,
    TOGGLE_SNAP,
    CREATE_THUMBNAIL,
    ZOOM_IN,
    ZOOM_OUT,
    SET_BACKGROUND_COLOR,
    SET_LAST_SAVED,
    SET_PRESENTATION_ID,
    FORCE_EXPLORER_DATA_REFRESH,
    SET_ZOOM,
    SET_MAX_SCROLL,
    SET_ACTIVE_MODE,
    SET_SHOW_FRAME_DRAWER,
    SET_SELECTED_OBJECT,
    SET_SHOW_EDIT_ELEMENT_WRAPPER,
    TOGGLE_SWITCH_WORKSPACE_MODAL,
    CAN_SHOW_TOUR_ALERT,
    SET_PRESENTATION_LOADING,
    SET_SHOW_RECIPE_MODAL,
    SET_ONBOARDING_CHECKLIST,
} from '../actions/uiActions'

const initialState = {
    editInsightOpen: false,
    sourceModalOpen: false,
    switchWorkspaceModalOpen: false,
    resetTrigger: false,
    snapThumbnail: false,
    showFileUpload: false,
    multiSelectElements: [],
    snap: true,
    zoom: 1,
    canShowTourAlert: false,
    presentatiobID: null,
    maxScroll: { x: 0, y: 0 },
    forceRefesh: null,
    activeMode: 'cursor',
    worksheetID: null,
    backgroundColor: '#FFF',
    showFrameDrawer: false,
    showEditElementWrapper: [false, undefined],
    selectedObject: '',
    isPresentationLoading: false,
    showRecipeModal: false,
    onboardingChecklist: localStorage.getItem('onboardingChecklist') || null,
}

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SWITCH_WORKSPACE_MODAL:
            return {
                ...state,
                switchWorkspaceModalOpen: action.payload,
            }
        case 'TOGGLE_INSIGHT_MODAL':
            return {
                ...state,
                editInsightOpen: !state.editInsightOpen,
            }
        case 'TOGGLE_SOURCE_MODAL':
            return {
                ...state,
                sourceModalOpen: !state.sourceModalOpen,
            }
        case TOGGLE_SNAP:
            return {
                ...state,
                snap: !state.snap,
            }
        case SET_RESET_TRIGGER:
            return {
                ...state,
                resetTrigger: action.payload,
            }
        case CREATE_THUMBNAIL:
            return {
                ...state,
                snapThumbnail: !state.snapThumbnail,
            }

        case TOGGLE_FILE_UPLOAD:
            return {
                ...state,
                showFileUpload: !state.showFileUpload,
            }

        // sheetlet cell change triggers chart refreshes
        case FORCE_EXPLORER_DATA_REFRESH:
            return {
                ...state,
                forceRefresh: !state.forceRefresh,
                worksheetID: action.worksheetID,
            }
        case 'HIDE_FILE_UPLOAD':
            return {
                ...state,
                showFileUpload: false,
            }
        case ZOOM_IN:
            return {
                ...state,
                zoom: Math.min(2, state.zoom + 0.1),
            }
        case ZOOM_OUT:
            return {
                ...state,
                zoom: Math.max(0.1, state.zoom - 0.1),
            }
        case SET_ZOOM:
            return {
                ...state,
                zoom: action.zoom,
            }
        case CAN_SHOW_TOUR_ALERT:
            return {
                ...state,
                canShowTourAlert: action.show,
            }
        // background color for the entire canvas
        case SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.color,
            }
        case SET_PRESENTATION_ID:
            return {
                ...state,
                presentationID: action.presentationID,
            }
        case SET_LAST_SAVED:
            return {
                ...state,
                lastSaved: action.lastSaved,
            }
        case SET_MAX_SCROLL:
            return {
                ...state,
                maxScroll: action.maxScroll,
            }
        case SET_ACTIVE_MODE:
            return {
                ...state,
                activeMode: action.mode,
            }
        case SET_SHOW_FRAME_DRAWER:
            return { ...state, showFrameDrawer: action.payload.value }
        case SET_SELECTED_OBJECT:
            return { ...state, selectedObject: action.payload.id }
        case SET_SHOW_EDIT_ELEMENT_WRAPPER:
            return { ...state, showEditElementWrapper: action.payload.value }
        case SET_PRESENTATION_LOADING:
            return { ...state, isPresentationLoading: action.payload }
        case SET_SHOW_RECIPE_MODAL:
            return { ...state, showRecipeModal: action.payload }
        case SET_ONBOARDING_CHECKLIST:
            if (action.payload === null) localStorage.removeItem('onboardingChecklist')
            else localStorage.setItem('onboardingChecklist', action.payload)
            return { ...state, onboardingChecklist: action.payload }
        case 'LOGOUT':
            return {
                ...initialState,
                onboardingChecklist: localStorage.getItem('onboardingChecklist') || null,
            }
        default:
            return state
    }
}

export default uiReducer
