import Box from '@mui/material/Box'
import { Checkbox } from '@mui/joy'
import IconButton from '@mui/material/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { getFilterStringForColumnFromList, getStringForFilter } from './Filter'

export function ColumnHeader({
    sheetID,
    headerName,
    table,
    inputQuery,
    currentColumns,
    setCurrentColumns,
    setOpen,
    setSelectedColumn,
    filterList,
    setOperator,
    setItems,
    setCategoryValues,
    setSelectedItems,
    server,
}) {
    function getTableColumn(table, columnName) {
        var column = null
        table.columns.forEach((col) => {
            if (col.columnName === columnName) {
                column = col
            }
        })
        return column
    }

    function handleClick() {
        setOpen(true)
        setSelectedColumn(headerName)
        var selected = []
        var filter = null
        for (let afObject of filterList) {
            if (afObject.attributeName === headerName) {
                selected = afObject.filterValue.values
                filter = afObject
                break
            }
        }
        var column = getTableColumn(table, headerName)
        if (column !== null && column.isMeasure) {
            setOperator(filter === null ? 'Equals' : filter.operator)
            setSelectedItems(
                filter === null
                    ? []
                    : filter.filterValue.values
                      ? [filter.filterValue.values[0]]
                      : [filter.filterValue.value]
            )
            setItems([])
        } else if (
            filter &&
            (filter.operator === 'GreaterThan' ||
                filter.operator === 'LessThan' ||
                filter.operator === 'GreaterThanOrEquals' ||
                filter.operator === 'LessThanOrEquals')
        ) {
            setOperator(filter.operator)
            setSelectedItems(
                filter.filterValue.values
                    ? [filter.filterValue.values[0]]
                    : [filter.filterValue.value]
            )
            setItems([])
        } else {
            setSelectedItems(selected ? selected : [])
            setOperator(filter === null ? 'Equals' : filter.operator)
            let action = {
                action: 'addOn',
                addOnAction: 'getCategoryValues',
                tableID: inputQuery.tableID,
                columnName: headerName,
                sheetID: sheetID,
            }
            if (inputQuery.changes) {
                action.changes = true
            }
            server.sheetPostData(action, setCategoryValues)
        }
    }

    function handleClickCheckbox(event) {
        setCurrentColumns((prevColumns) => {
            var isChecked = prevColumns.includes(event.target.name)
            if (!isChecked) {
                return [...prevColumns, event.target.name]
            } else {
                let result = prevColumns.filter((item) => item !== event.target.name)
                return [...result]
            }
        })
    }

    var filterStr = getFilterStringForColumnFromList(filterList, headerName)

    return (
        <Box
            sx={{
                width: 1,
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    m: 0,
                    p: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    width: 1,
                    height: 20,
                    textAlign: 'center',
                }}
            >
                <strong>{headerName}</strong>
            </Box>
            <Box
                sx={{
                    m: 0,
                    p: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    width: 1,
                    height: 20,
                    textAlign: 'center',
                }}
            >
                <Checkbox
                    name={headerName}
                    checked={currentColumns.includes(headerName)}
                    onChange={handleClickCheckbox}
                />
                <IconButton sx={{ b: 1 }} aria-label="filter" onClick={handleClick}>
                    <FilterAltIcon />
                </IconButton>
            </Box>
            {filterStr != null && filterList && (
                <Box
                    sx={{
                        m: 1,
                        display: 'flex',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        paddingY: 1,
                    }}
                >
                    {filterList?.map((filter, index) => {
                        return (
                            <Typography
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginRight: '8px',
                                }}
                                sx={{ fontSize: 12, lineHeight: 1.2 }}
                                key={index}
                            >
                                {getStringForFilter(filter)}
                            </Typography>
                        )
                    })}
                </Box>
            )}
        </Box>
    )
}
