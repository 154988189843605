// useApi.js
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../router/routes'
import {
    mobileAPIClient,
    sheetServerClient,
    canvasClient,
    worksheetsClient,
    workspaceClient,
    eventClient,
    botActionsClient,
} from './axiosClients'

export const useApi = (otherURL) => {
    const navigate = useNavigate()
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const apiURL = useSelector((state) => state.auth.apiURL)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const useAPIURL = isGuestMode ? apiURL.replace('mobile', 'guest-mobile') : apiURL
    const url = typeof otherURL === 'undefined' ? useAPIURL : otherURL

    const postData = async (action) => {
        if (Array.isArray(action)) {
            action.forEach((a) => {
                if (a && typeof a === 'object') {
                    a.workspaceID = a.workspaceID ?? workspaceID
                    a.userID = userID
                }
            })
        } else if (action && typeof action === 'object') {
            action.workspaceID = action.workspaceID ?? workspaceID
            action.userID = userID
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(action),
            })

            // let fetchOptions = {
            //     method: 'POST',
            //     mode: 'cors',
            //     cache: 'no-cache',
            //     credentials: 'same-origin',
            //     redirect: 'follow',
            //     referrerPolicy: 'no-referrer',
            //     body: JSON.stringify(action),
            // }
            //
            // // Conditionally add headers if action.isDev is defined (exists)
            // fetchOptions =
            //     typeof action.isDev !== 'undefined'
            //         ? {
            //               ...fetchOptions,
            //               headers: {
            //                   'Content-Type': 'application/json',
            //                   Authorization: `Bearer ${token}`,
            //               },
            //           }
            //         : fetchOptions
            //
            // // Perform the fetch request with the dynamically configured options
            // const response = await fetch(url, fetchOptions)

            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                if (response?.message === 'Unauthorized') {
                    console.log('*****Unauthorized')
                    navigate(ROUTES.LOGOUT)
                }
                // Not OK - throw an error
                throw new Error('Network response was not ok', response)
            }

            // Try parsing the response into JSON
            return await response.json()
        } catch (error) {
            console.log(error)
            // navigate(ROUTES.LOGOUT)
            // Log any errors that occur during the fetch() or parsing
            // throw error;
        }
    }
    return { postData }
}

export const sheetServerRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return sheetServerClient(options).then(onSuccess).catch(onError)
}

export const mobileAPIRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return mobileAPIClient(options).then(onSuccess).catch(onError)
}

export const canvasClientRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        console.log('canvasClientRequest error', error)
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }

    return canvasClient(options).then(onSuccess).catch(onError)
}

export const worksheetsClientRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return worksheetsClient(options).then(onSuccess).catch(onError)
}

export const eventLogClientRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return eventClient(options).then(onSuccess).catch(onError)
}

export const workspaceClientRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return workspaceClient(options).then(onSuccess).catch(onError)
}

export const botActionsClientRequest = async (options) => {
    const onSuccess = (response) => {
        const { data } = response
        return data
    }
    const onError = (error) => {
        return Promise.reject({
            message: error.message,
            code: error.code,
            response: error.response,
        })
    }
    return botActionsClient(options).then(onSuccess).catch(onError)
}
