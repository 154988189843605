import React from 'react'
import './ScoopFallback.css'
import { Box, Typography } from '@mui/material'
import BugBeetle from '../../../assets/icons/BugBeetle.svg'
import Button from '../../common/Button/Button'
import Toolbar from '@mui/material/Toolbar'
import ScoopLogo from '../../../assets/logo/ScoopLogo.svg'
import AppBar from '@mui/material/AppBar'

export const ScoopFallback = () => {
    return (
        <Box className={'error-boundary-screen'}>
            <AppBar>
                <Toolbar className={'navbar'}>
                    <img src={ScoopLogo} alt="Scoop Logo" />
                </Toolbar>
            </AppBar>
            <img src={BugBeetle} alt="Bug" style={{ width: 40 }} />
            <Typography className={'inter'} sx={{ mb: '20px', fontSize: '32px' }}>
                Oops… something went wrong!
            </Typography>
            <Typography className={'inter'}>
                It looks like there was an issue on our end. Our team has been notified and is
                working to resolve it.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography className={'inter'}>
                    Please try again later. If the problem continues, reach out to us at&nbsp;
                </Typography>
                <Typography
                    className={'inter error-support-text'}
                    onClick={() => {
                        window.location = 'mailto:support@scoopanalytics.com'
                    }}
                >
                    support@scoopanalytics.com
                </Typography>
            </Box>
            <Button
                onClick={() => window.location.reload()}
                className={'button-purple error-continue-button'}
            >
                Reload page
            </Button>
        </Box>
    )
}
