import { Box, Popover, Step, StepContent, StepLabel, Stepper } from '@mui/material'
import RedRocket from '../../../assets/icons/RedRocket.svg'
import CloseIcon from '../../../assets/icons/CloseIcon.svg'
import ArrowRight from '../../../assets/icons/ArrowRight.svg'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Checklist = ({ open, anchorEl, onClose, onPermanentClose }) => {
    const navigate = useNavigate()

    const currentStep = useSelector((state) => state.ui.onboardingChecklist) || -1

    const itemsList = [
        {
            title: 'Connect a data source',
            description: 'Easily sync your data using our variety of connection options.',
            onClick: () => navigate('/sources', { state: { openNewDataset: true } }),
            completed: Number(currentStep) > 0,
        },
        {
            title: 'Explore your data',
            description:
                'Dive deep into your datasets within Explorer, uncovering insights and trends to inform your business decisions.',
            onClick: () => navigate('/explorer'),
            completed: Number(currentStep) > 1,
        },
        {
            title: 'Create your first canvas',
            description:
                'Design interactive, presentation-ready canvases that combine data visualizations, narratives, and other elements to tell compelling data stories.',
            onClick: () => navigate('/canvas', { state: { openCanvasModal: true } }),
            completed: Number(currentStep) > 2,
        },
    ]

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
                marginTop: '16px',
                zIndex: 1300,
                pointerEvents: 'none',
            }}
            PaperProps={{ sx: { pointerEvents: 'auto' } }} // Ensure popover content remains interactive
            disableEnforceFocus
            disableAutoFocus
            disablePortal
            onClose={() => {}}
        >
            <Box
                sx={{
                    padding: '24px',
                    gap: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '446px',
                }}
            >
                <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    backgroundColor: '#FCE7EE',
                                    borderRadius: '50%',
                                    width: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40px',
                                }}
                            >
                                <img src={RedRocket} alt="rocket" />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    fontColor: '#000',
                                    fontFamily: 'Inter',
                                }}
                            >
                                Getting started
                            </Typography>
                        </Box>
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={onClose}
                            src={CloseIcon}
                            alt={'Close Icon'}
                        />
                    </Box>
                    <Stepper orientation="vertical">
                        {itemsList.map((step, index) => (
                            <Step key={index} completed={false} active={true}>
                                <StepLabel
                                    icon={
                                        step.completed ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: '22px',
                                                    height: '22px',
                                                    border: '1px dashed #979099',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        )
                                    }
                                >
                                    <Box
                                        onClick={step.onClick}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#2B1630',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                                fontFamily: 'Inter',
                                            }}
                                        >
                                            {step.title}
                                        </Typography>
                                        <img src={ArrowRight} alt={'Arrow'} />
                                    </Box>
                                </StepLabel>
                                <StepContent>
                                    <Typography
                                        sx={{
                                            color: '#635566',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                        }}
                                    >
                                        {step.description}
                                    </Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    <Typography
                        onClick={onPermanentClose}
                        sx={{
                            color: '#635566',
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Inter',
                            cursor: 'pointer',
                        }}
                    >
                        Don't show this again
                    </Typography>
                </Box>
            </Box>
        </Popover>
    )
}
