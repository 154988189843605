export function unpackFilters(filter) {
    let result = []
    if (filter === undefined || filter === null) {
        return result
    }
    if (filter.boperator !== undefined && filter.boperator === 'And') {
        for (let i = 0; i < filter.filters.length; i++) {
            result.push(getAttributeFilterObject(filter.filters[i]))
        }
    } else {
        result.push(getAttributeFilterObject(filter))
    }
    return result
}

export function packFilter(filterList) {
    if (filterList.length === 0) return null
    if (filterList.length === 1) return filterList[0]
    var result = {
        boperator: 'And',
        filters: [],
    }
    for (let i = 0; i < filterList.length; i++) {
        var filter = filterList[i]
        result.filters.push(filter)
    }
    return result
}

export function getAttributeFilterObject(columnFilter) {
    let result = {
        attributeName: null,
        operator: 'Equals',
        filterValue: {
            values: [],
        },
    }
    if (columnFilter.boperator !== undefined && columnFilter.boperator === 'Or') {
        result.attributeName = columnFilter.filters[0].attributeName
        result.operator = columnFilter.filters[0].operator
        for (let i = 0; i < columnFilter.filters.length; i++) {
            result.filterValue.values.push(columnFilter.filters[i].filterValue.values[0])
        }
    } else {
        result.attributeName = columnFilter.attributeName
        result.operator = columnFilter.operator
        if (columnFilter.filterValue) {
            result.filterValue.values = columnFilter.filterValue.values
        }
    }
    return result
}

export function getFilterStringForColumnFromList(filterList, column) {
    if (filterList == null) return null
    let filter = null
    for (let f of filterList) {
        if (f.attributeName === column) {
            filter = f
            break
        }
    }
    if (filter === null) {
        return null
    }
    if (filter.operator === 'IsNull') {
        return 'Is Null'
    } else if (filter.operator === 'IsNotNull') {
        return 'Is Not Null'
    } else {
        var result = getOperationString(filter.operator) + ' '
        for (let i = 0; i < filter.filterValue.values.length; i++) {
            if (i > 0) {
                result += ' Or '
            }
            result += filter.filterValue.values[i]
        }
    }
    return result
}

function getAttributeFilterString(filter) {
    var result = filter.attributeName + getOperationString(filter.operator)
    for (let i = 0; i < filter.filterValue.values.length; i++) {
        if (i > 0) {
            result += ','
        }
        result += filter.filterValue.values[i]
    }
    return result
}

export function getFilterString(filter) {
    let result = ''
    if (filter === undefined || filter === null) {
        return result
    }
    if (filter.boperator !== undefined) {
        result += '('
        for (let i = 0; i < filter.filters.length; i++) {
            if (i > 0) {
                result += ' ' + filter.boperator + ' '
            }
            result += getFilterString(filter)
        }
        result += ')'
    } else {
        result += getAttributeFilterString(filter)
    }
    return result
}

function getOperationString(operator) {
    if (operator === 'Equals') return '='
    if (operator === 'GreaterThan') return '>'
    if (operator === 'GreaterThanOrEquals') return '>='
    if (operator === 'LessThan') return '<'
    if (operator === 'LessThanOrEquals') return '<='
    if (operator === 'Like') return 'Like'
    if (operator === 'IsNull') return 'Is Null'
    if (operator === 'IsNotNull') return 'Is Not Null'
    return operator
}

export function getStringForFilter(filter) {
    var result = filter.attributeName + ' ' + getOperationString(filter.operator) + ' '
    if (!filter.filterValue) {
        return null
    }
    for (let i = 0; i < filter.filterValue.values.length; i++) {
        if (i > 0) {
            result += ' Or '
        }
        result += filter.filterValue.values[i]
    }
    return result
}
