import React, { useEffect, useState } from 'react'
import './Explorer.css'
import { useSelector } from 'react-redux'
import { Box, Drawer, IconButton, Tooltip, Typography } from '@mui/material'
import { Server } from '../../../api/Server'
import ChartState, { backwardsFlag } from '../../Insights/ChartState.js'
import { DataTab } from './DrawerTabs/DataTab'
import { ConfigTab } from './DrawerTabs/ConfigTab'
import { Insight } from '../../Insights/Insight'
import { SaveDialog } from '../../Insights/SaveDialog'
import { OpenDialog } from '../../Insights/OpenDialog'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { removeFontScaleFactors, saveInsight } from '../../Objects/Insight/InsightAPI'
import { useApi } from '../../../api/api'
import SaveChangesDialog from '../../common/Dialog/SaveChangesDialog'
import { FilterTab } from './DrawerTabs/FilterTab'
import { Switch } from '../../common/Switch/Switch'
import ChartPlaceholder from '../../../assets/images/chart-placeholder.svg'
import EmptyExplorer from '../../../assets/icons/EmptyExplorer.svg'
import Save from '../../../assets/icons/Save.svg'
import SaveAs from '../../../assets/icons/SaveAs.svg'
import FolderWhite from '../../../assets/icons/FolderWhite.svg'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import Button from '../../common/Button/Button'
import Dialog from '../../common/Dialog/Dialog'
import { TimeRangeDialog } from '../../Insights/TimeRangeDialog'
import { StyleTab } from './DrawerTabs/Style/StyleTab'
import { Tab } from '../../common/Tab/Tab'
import { DEFAULT_CHART_PREFERENCES, TITLE_DEFAULT_VALUES } from './DrawerTabs/Style/styleConsts'
import { ScoopTheme } from '../../Insights/Style'
import { cloneDeep, merge } from 'lodash'
import MagicWand from '../../../assets/icons/MagicWand.svg'
import { GenerateInsightDialog } from '../../Insights/GenerateInsightDialog'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useWorkspaceMetadata } from '../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import { EmptyDataset } from '../../common/EmptyDataset/EmptyDataset'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { getWorkspaceTheme } from '../../Insights/utils'

const drawerSx = {
    width: 300,
    '& .MuiDrawer-root': {
        position: 'absolute',
    },
    '& .MuiPaper-root': {
        position: 'absolute',
    },
    '& .MuiDrawer-paper': {
        width: 300,
    },
}

const leftDrawerTabs = [
    { label: 'Data', key: 'data' },
    { label: 'Filters', key: 'filters' },
]

const rightDrawerTabs = [
    { label: 'Properties', key: 'properties' },
    { label: 'Style', key: 'style' },
]

export const Explorer = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const [selectedLeftTab, setSelectedLeftTab] = useState(leftDrawerTabs[0].key)
    const [selectedRightTab, setSelectedRightTab] = useState(rightDrawerTabs[0].key)
    const [server, setServer] = useState(new Server(workspaceID, userID, token))
    const [dataTabOpen, setDataTabOpen] = useState(true)
    const [configTabOpen, setConfigTabOpen] = useState(true)
    const [loadOpen, setLoadOpen] = useState(false)
    const [insightToSave, setInsightToSave] = useState(null)
    const [insightResult, setInsightResult] = useState(null)
    const [saveOpen, setSaveOpen] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [loadingTableInsight, setLoadingTableInsight] = useState(false)
    const [loadingChartInsight, setLoadingChartInsight] = useState(false)
    const [config, setConfig] = useState(state?.editmodeConfig || ChartState.getDefaultConfig())
    const [chartState, setChartState] = useState(new ChartState(server, config, setConfig))
    const [advanced, setAdvanced] = useState(!!config.filter || config.categoryAxis !== 'Time')
    const [analyzeChanges, setAnalyzeChanges] = useState(false)
    const [filterSelectedColumn, setFilterSelectedColumn] = useState(undefined)
    const [filterOpen, setFilterOpen] = useState(undefined)
    const [columnLoading, setColumnLoading] = useState(false)
    const [showResetDialog, setShowResetDialog] = useState(false)
    const [timeRangeDialogOpen, setTimeRangeDialogOpen] = useState(false)
    const [dateFlag, setDateFlag] = useState(false)
    const [isExistingInsight, setIsExistingInsight] = useState(false)
    const [worksheetMetrics, setWorksheetMetrics] = useState([])
    const [generateInsightDialogOpen, setGenerateInsightDialogOpen] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(null)
    const { postData } = useApi()
    const {
        workspaceMetadata,
        refetch,
        isLoading: workspaceMetadataLoading,
    } = useWorkspaceMetadata()

    useEffect(() => {
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer)
        const newChartState = new ChartState(newServer, config, setConfig)
        setChartState(newChartState)
    }, [userID, token, workspaceID])

    useEffect(() => {
        if (state?.editmodeConfig) loadFromSavedInsight(config)
    }, [chartState])

    useEffect(() => {
        // update metrics if open from saved insight
        if (config.worksheetID && worksheetMetrics.length === 0) {
            const action = {
                action: 'getTimeSeries',
                metrics: [],
                calendarType: config.calendarType,
                period: config.period,
                split: true,
                process: false,
                timeRange: config.timeRange,
                worksheetID: config.worksheetID,
            }
            if (config.sheetName) action.sheetName = config.sheetName
            if (config.rangeName) action.rangeName = config.rangeName
            server.postData(action, (r) => {
                setWorksheetMetrics(r.sheetColumns.filter((col) => col.isMeasure))
                const newConfig = { ...config }
                newConfig.worksheetColumns = r.sheetColumns
                setConfig(newConfig)
            })
        }
    }, [config.worksheetID, server])

    const handleReset = () => {
        chartState.clear()
        setIsExistingInsight(false)
        setInsightToSave(null)
        if (state?.editmodeConfig) navigate('.', { replace: true })
        setShowResetDialog(false)
    }

    function handleSaveInsight() {
        const configToSave = cloneDeep(config)
        configToSave.selectedDates = Object.fromEntries(configToSave.selectedDates)
        if (configToSave.view === 'chart' && chartState.valueAxis)
            configToSave.metricsOrder = chartState.valueAxis.map((a) => ({
                name: a.name,
                position: a.position,
            }))
        if (configToSave.view !== 'kpi') {
            configToSave.styleOverrides.dimensions = {
                width: window.innerWidth - 600,
                height: window.innerHeight - 112,
            }
        } else {
            configToSave.styleOverrides.dimensions = {
                width: 200,
                height: 200,
            }
        }
        setInsightToSave({
            insightName: insightResult ? insightResult.insightName : '',
            insightKey: null,
            workspaceID: server.workspaceID,
            savedObject: JSON.stringify(configToSave),
        })
        setSaveOpen(true)
    }

    function handleAdvancedSwitch() {
        setAdvanced(!advanced)
        const newConfig = { ...config }
        newConfig.seriesTypeMap = new Map()
        setConfig(newConfig)
    }

    function getWorkspaceMetadata(chartState, callback) {
        refetch().then((result) => {
            chartState.workspaceMetadata = result
            setChartState(chartState)
            callback && callback(result)
        })
    }

    const loadFromSavedInsight = (insight) => {
        if (!insight) {
            handleReset()
            return
        }
        if (insight.view === 'chart') setLoadingChartInsight(true)
        if (!(insight.seriesTypeMap instanceof Map)) insight.seriesTypeMap = new Map()
        if (!insight.usedDrillAttributes) insight.usedDrillAttributes = []
        // START temp backwards stuff
        if (!insight.selectedTableKpis) {
            insight.selectedTableColumns = []
            insight.selectedTableKpis = []
            insight.selectedTables = []
            insight.displayRows = 50
            insight.tableTimeSeries = true
            if (insight.view === 'table') {
                insight.selectedItems.forEach((item) => {
                    if (!insight.selectedTables.includes(item.reportSeriesTableID)) {
                        insight.selectedTables.push(item.reportSeriesTableID)
                    }
                    if (
                        !insight.selectedTableColumns.includes(item.kpi) ||
                        !insight.selectedTableColumns.includes(item.columnName)
                    ) {
                        if (item.columnName) {
                            // Measure
                            workspaceMetadata?.inboxes?.forEach((inbox) => {
                                inbox.tables.forEach((table) => {
                                    if (table.reportSeriesTableID === item.reportSeriesTableID) {
                                        table.columns.forEach((col) => {
                                            if (
                                                col.columnName === item.columnName ||
                                                insight.tableDrillAttributes.includes(
                                                    col.columnName
                                                )
                                            ) {
                                                insight.selectedTableColumns.push({
                                                    ...col,
                                                    reportSeriesTableID: table.reportSeriesTableID,
                                                })
                                            }
                                        })
                                    }
                                })
                            })
                        } else {
                            // KPI
                            workspaceMetadata.kpis.forEach((kpi) => {
                                if (kpi.metricName === item.kpi) insight.selectedTableKpis.push(kpi)
                                workspaceMetadata?.inboxes?.forEach((inbox) => {
                                    inbox.tables.forEach((table) => {
                                        if (table.reportSeriesTableID === kpi.reportSeriesTableID) {
                                            table.columns.forEach((col) => {
                                                if (
                                                    insight.tableDrillAttributes.includes(
                                                        col.columnName
                                                    )
                                                ) {
                                                    insight.selectedTableColumns.push({
                                                        ...col,
                                                        reportSeriesTableID:
                                                            table.reportSeriesTableID,
                                                    })
                                                }
                                            })
                                        }
                                    })
                                })
                            })
                        }
                    }
                })
            }
        }
        if (!insight.worksheetColumns) insight.worksheetColumns = []
        if (!insight.styleOverrides) insight.styleOverrides = { ...DEFAULT_CHART_PREFERENCES }
        merge(insight.styleOverrides, DEFAULT_CHART_PREFERENCES)
        // FOR BACKWARDS COMPATIBILITY
        if (!Array.isArray(insight.styleOverrides.xAxis))
            insight.styleOverrides.xAxis = cloneDeep([insight.styleOverrides.xAxis])
        if (!Array.isArray(insight.styleOverrides.yAxis))
            insight.styleOverrides.yAxis = cloneDeep([insight.styleOverrides.yAxis])
        if (!insight.styleOverrides.dimensions) {
            if (insight.styleOverrides.title.textStyle.fontScaleFactor) {
                if (insight.view !== 'kpi') {
                    insight.styleOverrides.dimensions = {
                        width:
                            (insight.styleOverrides.title.textStyle.fontSize ??
                                TITLE_DEFAULT_VALUES.textStyle.fontSize) /
                            insight.styleOverrides.title.textStyle.fontScaleFactor.x,
                        height:
                            (insight.styleOverrides.title.textStyle.fontSize ??
                                TITLE_DEFAULT_VALUES.textStyle.fontSize) /
                            insight.styleOverrides.title.textStyle.fontScaleFactor.y,
                    }
                } else {
                    insight.styleOverrides.dimensions = {
                        width: 200,
                        height: 200,
                    }
                }
            } else {
                if (insight.view !== 'kpi') {
                    insight.styleOverrides.dimensions = {
                        width: window.innerWidth - 600,
                        height: window.innerHeight - 112,
                    }
                } else {
                    insight.styleOverrides.dimensions = {
                        width: 200,
                        height: 200,
                    }
                }
            }
            removeFontScaleFactors(insight)
        }
        if (!insight.styleOverrides.pie || !insight.styleOverrides.donut) {
            insight.styleOverrides.pie = { ...DEFAULT_CHART_PREFERENCES.pie }
            insight.styleOverrides.donut = {
                ...DEFAULT_CHART_PREFERENCES.donut,
            }
        }
        if (!insight.styleOverrides.pie.itemStyle) {
            insight.styleOverrides.pie.itemStyle = {
                ...DEFAULT_CHART_PREFERENCES.pie.itemStyle,
            }
            insight.styleOverrides.donut.itemStyle = {
                ...DEFAULT_CHART_PREFERENCES.donut.itemStyle,
            }
        }
        if (!insight.styleOverrides.pie.label) {
            insight.styleOverrides.pie.label = {
                ...DEFAULT_CHART_PREFERENCES.pie.label,
            }
            insight.styleOverrides.donut.label = {
                ...DEFAULT_CHART_PREFERENCES.donut.label,
            }
        }
        if (!insight.sortColumns) insight.sortColumns = []
        if (!insight.kpiCompareType) {
            insight.kpiCompareType = 'percentage'
            insight.kpiCompareTarget = undefined
        }
        if (insight.worksheetID && !insight.backwardsFlag) {
            insight.backwardsFlag = backwardsFlag
            insight.selectedTableColumns = insight.selectedTableColumns.map((c) => {
                if (c.isMeasure && c.reportSeriesTableID.includes('orphan'))
                    return {
                        ...c,
                        worksheetID: insight.worksheetID,
                        rangeName: insight.rangeName,
                    }
                else return c
            })
            insight.selectedItems = insight.selectedItems
                .map((i) => ({
                    measureName: i.measureName || i.columnName,
                    worksheetID: insight.worksheetID,
                    rangeName: insight.rangeName,
                }))
                .filter((i) => i.measureName)
        }
        if (!insight.metricsOrder) insight.metricsOrder = []
        // END temp backwards stuff
        if (!(insight.selectedDates instanceof Map)) {
            if (insight.selectedDates) {
                insight.selectedDates = new Map(Object.entries(insight.selectedDates))
            } else {
                insight.selectedDates = new Map()
            }
        }
        setConfig(insight)
        setIsExistingInsight(true)
        if (insight.view === 'chart') {
            chartState.getResults({ ...insight }, () => {
                setLoadingChartInsight(false)
                setLoadingTableInsight(false)
            })
        }
    }

    const handleCreateFilter = (column) => {
        if (!advanced) handleAdvancedSwitch()
        setSelectedLeftTab('filters')
        setFilterOpen(true)
        setFilterSelectedColumn(column)
    }

    const resetFilterState = () => {
        setFilterOpen(undefined)
        setFilterSelectedColumn(undefined)
    }

    const shouldRenderInsight = () => {
        if (config.view === 'table')
            return (
                config.selectedTableColumns.length > 0 ||
                config.selectedTableKpis.length > 0 ||
                config.worksheetID
            )
        if (config.view === 'chart') {
            return config.selectedItems.length > 0 && chartState.hasData()
        }
        if (config.view === 'kpi') return config.selectedItems.length > 0
        return true
    }

    const getTheme = () => {
        if (config && config.themeID) {
            if (config.themeID === 'defaultTheme') {
                return {
                    themeID: 'defaultTheme',
                    themeName: 'Default theme',
                    colorScheme: {
                        backgroundColor: ScoopTheme.backgroundColor,
                        colors: ScoopTheme.color,
                        darkTheme: false,
                    },
                }
            }
            return getWorkspaceTheme(config.themeID, workspaceMetadata)
        }
        return undefined
    }

    const getSummaryTitle = () => {
        if (config?.insightName && config?.insightName.length > 0) {
            return config.insightName
        } else if (config.chartTitle && config?.chartTitle.length > 0) {
            return config.chartTitle
        }
    }

    const getInsightBackgroundClass = () => {
        const theme = getTheme()
        if (shouldRenderInsight() && config.view === 'chart') {
            if (
                theme &&
                (theme.colorScheme.backgroundColor === 'rgba(0,0,0,0)' ||
                    theme.colorScheme.backgroundColor === '#00000000')
            ) {
                if (theme?.colorScheme?.darkTheme) {
                    return 'insight-container-dark'
                } else {
                    return 'insight-container-light'
                }
            }
        }
        return ''
    }

    const handleSaveInsightChanges = (goBack, config) => {
        const returnToCanvas = !!state?.editmodeConfig
        const configToSave = cloneDeep(config)
        if (configToSave.view === 'chart' && chartState.valueAxis)
            configToSave.metricsOrder = chartState.valueAxis.map((a) => ({
                name: a.name,
                position: a.position,
            }))
        if (configToSave.view !== 'kpi') {
            configToSave.styleOverrides.dimensions = {
                width: window.innerWidth - 600,
                height: window.innerHeight - 112,
            }
        } else {
            configToSave.styleOverrides.dimensions = {
                width: 200,
                height: 200,
            }
        }
        saveInsight(
            returnToCanvas ? state?.insightKey : configToSave.insightKey,
            returnToCanvas ? state?.insightName : configToSave.insightName,
            workspaceID,
            userID,
            configToSave,
            postData,
            () => {
                if (goBack) {
                    setConfirmDialogOpen(false)
                    if (returnToCanvas) navigate(ROUTES.CANVAS_DASHBOARD + '/' + state?.canvasID)
                }
            }
        )
    }

    const handleOnGenerateWithAISuccess = (insight) => {
        getWorkspaceMetadata(chartState)
        loadFromSavedInsight(insight.insightObject)
    }

    const resetActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button
                onClick={() => setShowResetDialog(false)}
                className={'primary-button'}
                text={'Cancel'}
            />
            <Button
                onClick={handleReset}
                className={'primary-button button-purple'}
                text={'Reset'}
            />
        </Box>
    )

    const handleGoToDataset = () => {
        navigate(ROUTES.SOURCES, { state: { openNewDataset: true } })
    }

    return (
        <Box className={'screen-container'} sx={{ overflow: 'hidden' }}>
            <Box className={'explorer-nav'}>
                <Typography className={'inter'} sx={{ fontSize: '18px' }}>
                    Explorer
                    <Tooltip
                        placement={'bottom'}
                        arrow={true}
                        title={
                            <Typography
                                className={'inter'}
                                sx={{ fontSize: '14px', fontWeight: 400, color: '#fffle' }}
                            >
                                Use
                                <a
                                    href={
                                        'https://docs.scoopanalytics.com/docs/visualizing-charts-and-tables'
                                    }
                                    style={{ color: '#E50B54', textDecoration: 'none' }}
                                    target={'_blank'}
                                >
                                    {' '}
                                    our guide{' '}
                                </a>
                                to learn more.
                            </Typography>
                        }
                        componentsProps={{
                            tooltip: { sx: { backgroundColor: '#201024' } },
                            arrow: { sx: { color: '#201024' } },
                        }}
                    >
                        <span>
                            <IconButton disabled>
                                <InfoOutlinedIcon sx={{ color: 'white' }} fontSize={'small'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Typography>
                <Typography className={'inter'} sx={{ fontSize: '18px' }}>
                    {getSummaryTitle()}
                </Typography>
                {!workspaceMetadataLoading && workspaceMetadata?.inboxes?.length > 0 && (
                    <Box className={'explorer-nav-actions'}>
                        <Tooltip title={'Generate New Summary with AI'}>
                            <IconButton
                                sx={{
                                    border: '1px solid #635566',
                                    borderRadius: '5px',
                                    padding: '4px',
                                }}
                                onClick={() => setGenerateInsightDialogOpen(true)}
                            >
                                <img alt={'Generate Summary'} src={MagicWand} />
                            </IconButton>
                        </Tooltip>
                        <Box
                            onClick={() => setLoadOpen(true)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '8px',
                                border: '1px solid #635566',
                                borderRadius: '5px',
                                padding: '4px',
                                cursor: 'pointer',
                                gap: '4px',
                            }}
                        >
                            <img src={FolderWhite} alt={'Folder'} />
                            <Typography
                                className={'inter'}
                                sx={{ fontSize: '14px', color: 'white', fontWeight: 500 }}
                            >
                                Open
                            </Typography>
                        </Box>
                        {(config.selectedItems?.length > 0 ||
                            (config.worksheetID && config.rangeName) ||
                            config.selectedTableColumns.length > 0 ||
                            config.selectedTableKpis.length > 0) && (
                            <Box
                                onClick={handleSaveInsight}
                                sx={{
                                    padding: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '8px',
                                    border: '1px solid #635566',
                                    borderRadius: '5px',
                                    paddingRight: '8px',
                                    cursor: 'pointer',
                                    gap: '4px',
                                }}
                            >
                                <img src={SaveAs} alt={'Save as'} />
                                <Typography
                                    className={'inter'}
                                    sx={{ fontSize: '14px', color: 'white', fontWeight: 500 }}
                                >
                                    Save As
                                </Typography>
                            </Box>
                        )}
                        {isExistingInsight && (
                            <Box
                                onClick={() => setConfirmDialogOpen(true)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '4px',
                                    marginLeft: '8px',
                                    border: '1px solid #635566',
                                    borderRadius: '5px',
                                    paddingRight: '8px',
                                    cursor: 'pointer',
                                    gap: '4px',
                                }}
                            >
                                <img src={Save} alt={'Save'} />
                                <Typography
                                    className={'inter'}
                                    sx={{ fontSize: '14px', color: 'white', fontWeight: 500 }}
                                >
                                    Save
                                </Typography>
                            </Box>
                        )}
                        {/*<Tooltip title="Toggle Advanced Options" sx={{ ml: 3 }}>*/}
                        {/*    <Box*/}
                        {/*        sx={{*/}
                        {/*            display: 'flex',*/}
                        {/*            alignItems: 'center',*/}
                        {/*            padding: '8px',*/}
                        {/*            justifyContent: 'space-between',*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Typography*/}
                        {/*            sx={{*/}
                        {/*                fontSize: '12px',*/}
                        {/*                fontWeight: 600,*/}
                        {/*                mr: '10px',*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Advanced*/}
                        {/*        </Typography>*/}
                        {/*        <Switch*/}
                        {/*            checked={advanced}*/}
                        {/*            onChange={handleAdvancedSwitch}*/}
                        {/*            disabled={*/}
                        {/*                config.selectedItems?.length === 0 &&*/}
                        {/*                config.selectedTableColumns.length === 0*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </Box>*/}
                        {/*</Tooltip>*/}
                        {(config.worksheetID ||
                            config.selectedItems.length > 0 ||
                            config.selectedTableColumns.length > 0 ||
                            config.selectedTableKpis.length > 0) && (
                            <Button
                                className={'button-purple reset-insight small'}
                                style={{ padding: '4px' }}
                                onClick={() => setShowResetDialog(true)}
                            >
                                <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                    Reset
                                </Typography>
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
            <Box className={'explorer-content'}>
                {!workspaceMetadataLoading && workspaceMetadata?.inboxes?.length === 0 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <EmptyDataset
                            title={'Welcome to Explorer'}
                            description={
                                'Dive deep into your data with Scoop’s Explorer—analyze, slice, and summarize your dataset to create stunning visual summaries and uncover key insights.'
                            }
                            icon={EmptyExplorer}
                            onClick={handleGoToDataset}
                        />
                    </Box>
                ) : (
                    <>
                        <Drawer
                            open={dataTabOpen}
                            anchor={'left'}
                            variant={'persistent'}
                            sx={drawerSx}
                        >
                            <Tab
                                tabs={leftDrawerTabs}
                                value={selectedLeftTab}
                                onChange={(e, value) => setSelectedLeftTab(value)}
                            />
                            <Box className={'drawer-content'}>
                                {selectedLeftTab === 'data' && (
                                    <DataTab
                                        config={config}
                                        setConfig={setConfig}
                                        chartState={chartState}
                                        server={server}
                                        getWorkspaceMetadata={(callback) =>
                                            getWorkspaceMetadata(chartState, callback)
                                        }
                                        workspaceID={workspaceID}
                                        worksheetMetrics={worksheetMetrics}
                                        setWorksheetMetrics={setWorksheetMetrics}
                                    />
                                )}
                                {selectedLeftTab === 'filters' && (
                                    <FilterTab
                                        config={config}
                                        setConfig={setConfig}
                                        chartState={chartState}
                                        advanced={advanced}
                                        workspaceMetadata={workspaceMetadata}
                                        server={server}
                                        getWorkspaceMetadata={() =>
                                            getWorkspaceMetadata(chartState)
                                        }
                                        analyzeChanges={analyzeChanges}
                                        initialOpen={filterOpen}
                                        initialSelectedColumn={filterSelectedColumn}
                                        resetFilterState={resetFilterState}
                                        setTimeRangeDialogOpen={setTimeRangeDialogOpen}
                                    />
                                )}
                            </Box>
                        </Drawer>
                        <Drawer
                            open={configTabOpen}
                            anchor={'right'}
                            variant={'persistent'}
                            sx={drawerSx}
                        >
                            <Tab
                                tabs={rightDrawerTabs}
                                value={selectedRightTab}
                                onChange={(e, value) => setSelectedRightTab(value)}
                            />
                            <Box
                                className={'drawer-content'}
                                sx={{
                                    height: 'calc(100% - 48)',
                                    paddingBottom: '16px',
                                }}
                            >
                                {selectedRightTab === 'properties' && (
                                    <ConfigTab
                                        config={config}
                                        setConfig={setConfig}
                                        chartState={chartState}
                                        advanced={advanced}
                                        handleAdvancedToggle={handleAdvancedSwitch}
                                        analyzeChanges={analyzeChanges}
                                        setAnalyzeChanges={setAnalyzeChanges}
                                        workspaceMetadata={workspaceMetadata}
                                        server={server}
                                        getWorkspaceMetadata={() =>
                                            getWorkspaceMetadata(chartState)
                                        }
                                        columnLoading={columnLoading}
                                        setColumnLoading={setColumnLoading}
                                        dateFlag={dateFlag}
                                        setDateFlag={setDateFlag}
                                        setTimeRangeDialogOpen={setTimeRangeDialogOpen}
                                    />
                                )}
                                {selectedRightTab === 'style' && (
                                    <StyleTab
                                        config={config}
                                        setConfig={setConfig}
                                        chartState={chartState}
                                        setChartState={setChartState}
                                        workspaceMetadata={workspaceMetadata}
                                        getWorkspaceMetadata={(callback) =>
                                            getWorkspaceMetadata(chartState, callback)
                                        }
                                        server={server}
                                        handleSaveInsight={(config) =>
                                            handleSaveInsightChanges(false, config)
                                        }
                                    />
                                )}
                            </Box>
                        </Drawer>
                        <Box className={`insight-container ${getInsightBackgroundClass()}`}>
                            {loadingChartInsight || loadingTableInsight ? (
                                <Box
                                    sx={{
                                        height: 500,
                                        display: 'grid',
                                        placeContent: 'center',
                                    }}
                                >
                                    <ScoopLoader size={56} />
                                </Box>
                            ) : shouldRenderInsight() ? (
                                <Insight
                                    chartProperties={chartState}
                                    setChartProperties={setChartState}
                                    config={config}
                                    setConfig={setConfig}
                                    server={server}
                                    advanced={advanced}
                                    handleAdvancedToggle={handleAdvancedSwitch}
                                    analyzeChanges={analyzeChanges}
                                    handleCreateFilter={handleCreateFilter}
                                    setColumnLoading={setColumnLoading}
                                    workspaceMetadata={workspaceMetadata}
                                    dateFlag={dateFlag}
                                    theme={getTheme()}
                                    clickable
                                    setDataTabOpen={setDataTabOpen}
                                    dataTabOpen={dataTabOpen}
                                    setConfigTabOpen={setConfigTabOpen}
                                    configTabOpen={configTabOpen}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <img src={ChartPlaceholder} alt={'chart'} />
                                    <Typography sx={{ marginTop: '40px' }}>
                                        No data input
                                    </Typography>
                                    <Typography sx={{ color: '#979099' }}>
                                        Select a KPI or Source Metric to get started
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </Box>
            {saveOpen && (
                <SaveDialog
                    open={saveOpen}
                    setOpen={setSaveOpen}
                    insight={insightToSave}
                    setInsight={setInsightToSave}
                    workspaceMetadata={workspaceMetadata}
                    server={server}
                />
            )}
            {generateInsightDialogOpen && (
                <GenerateInsightDialog
                    open={generateInsightDialogOpen}
                    onClose={() => setGenerateInsightDialogOpen(false)}
                    inboxes={workspaceMetadata?.inboxes || []}
                    onGenerateSuccess={(insight) => handleOnGenerateWithAISuccess(insight)}
                    onGenerateError={(error) =>
                        setSnackbarOpen({
                            msg: typeof error === 'string' ? error : 'Error generating summary',
                            severity: 'error',
                        })
                    }
                    generateInsight={true}
                />
            )}
            {loadOpen && (
                <OpenDialog
                    open={loadOpen}
                    setOpen={setLoadOpen}
                    setInsight={loadFromSavedInsight}
                    workspaceMetadata={workspaceMetadata}
                    server={server}
                    window={'Explorer'}
                    resetState={handleReset}
                />
            )}
            <SaveChangesDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                handleSave={() => {
                    if (!!state?.editmodeConfig || config.insightKey)
                        handleSaveInsightChanges(true, config)
                }}
                handleDontSave={() => setConfirmDialogOpen(false)}
            />
            <TimeRangeDialog
                open={timeRangeDialogOpen}
                setOpen={setTimeRangeDialogOpen}
                config={config}
                setConfig={setConfig}
                chartProperties={chartState}
            />
            <Dialog
                open={showResetDialog}
                onClose={() => setShowResetDialog(false)}
                actions={resetActions}
                title={'Reset insight'}
            >
                <Typography className={'inter'}>
                    Resetting this insight will delete previous progress
                </Typography>
            </Dialog>
            {snackbarOpen && (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(null)}
                        severity={snackbarOpen?.severity}
                        variant="filled"
                    >
                        {snackbarOpen?.msg}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    )
}
