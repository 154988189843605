import { Box, Dialog } from '@mui/material'
import Typography from '@mui/material/Typography'
import CanvasOnboardingImg from '../../../assets/images/CanvasOnboarding.png'
import Button from '../Button/Button'

export const PersonalWorkspaceOnboarding = ({ onClick }) => {
    return (
        <Dialog
            padding={'0px'}
            open={true}
            PaperProps={{
                sx: {
                    width: '650px',
                    maxWidth: '650px',
                    padding: '0px',
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <img src={CanvasOnboardingImg} alt={'Image'} style={{ height: '260px' }} />
                <Box
                    sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '48px' }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Typography
                            sx={{ fontSize: '28px', color: '#201024', fontFamily: 'Inter' }}
                        >
                            Welcome to your Workspace
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: '#635566',
                                fontFamily: 'Inter',
                                flexWrap: 'wrap',
                                textAlign: 'center',
                            }}
                        >
                            Your data’s story starts here. Discover trends, uncover insights, and
                            turn numbers into action – all in one place.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                        <Button
                            sx={{ width: '218px' }}
                            className={'primary-button button-purple'}
                            onClick={onClick}
                        >
                            Scoop your data
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}
