import { mobileAPIRequest, workspaceClientRequest } from '../../api'

export const getUserWorkspaces = async (userID) => {
    const action = {
        action: 'getObjects',
        attributeName: 'userID',
        attributeValue: userID,
        class: 'scoop.workspace.Workspace',
        relatedClass: 'scoop.workspace.WorkspaceUser',
    }
    const result = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    if (result) return result.objects
}

export const createWorkspace = async (workspaceName, userName) => {
    const action = {
        action: 'createWorkspace',
        workspaceName,
        userName,
    }
    const result = await workspaceClientRequest({
        method: 'post',
        data: action,
    })
    return result
}

export const renameWorkspace = async (workspaceName, workspaceID) => {
    const action = {
        action: 'renameWorkspace',
        workspaceName,
        workspaceID,
    }
    return await workspaceClientRequest({
        method: 'post',
        data: action,
    })
}

export const deleteWorkspace = async (workspaceID) => {
    const action = {
        action: 'deleteWorkspace',
        workspaceID,
    }
    return await workspaceClientRequest({
        method: 'post',
        data: action,
    })
}

export const submitRequest = async (email, message) => {
    const action = {
        action: 'featureRequest',
        data: {
            email,
            message,
        },
    }
    return await workspaceClientRequest({
        method: 'post',
        data: action,
    })
}
