import React from 'react'
import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { InsightService } from './InsightService'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
export const NewInsightComponent = ({ config }) => {
    const { data, isLoading } = useQuery({
        queryKey: [config],
        queryFn: () => InsightService.fetchInsightData(config),
    })

    return (
        <Box>
            {isLoading ? (
                <Box
                    sx={{
                        height: '100%',
                        display: 'grid',
                        placeContent: 'center',
                    }}
                >
                    <ScoopLoader size={56} />
                </Box>
            ) : (
                <Box></Box>
            )}
        </Box>
    )
}
