export const getToken = () => {
    return localStorage.getItem('userToken')
}

export const getWorkspaceID = () => {
    return localStorage.getItem('workspaceID')
}

export const getUserID = () => {
    return localStorage.getItem('userID')
}

export const setWorkspaceID = (workspaceID) => {
    localStorage.setItem('workspaceID', workspaceID)
}

export const getLastUsedWorkspaces = () => {
    const savedUsedWorkspaces = localStorage.getItem('lastUsedWorkspaces')
    let lastUsedWorkspaces = new Map()
    if (savedUsedWorkspaces) {
        lastUsedWorkspaces = JSON.parse(savedUsedWorkspaces, (key, value) => {
            if (typeof value === 'object' && value !== null)
                if (value.dataType === 'Map') return new Map(value.value)
            return value
        })
        return lastUsedWorkspaces
    }
    return new Map()
}

export const setLastUsedWorkspaces = (userID, workspaceID, workspaceName) => {
    const lastUsedWorkspaces = getLastUsedWorkspaces()
    lastUsedWorkspaces.set(userID, [workspaceID, workspaceName])
    localStorage.setItem(
        'lastUsedWorkspaces',
        JSON.stringify(lastUsedWorkspaces, (key, value) => {
            if (value instanceof Map) return { dataType: 'Map', value: Array.from(value.entries()) }
            else return value
        })
    )
}
