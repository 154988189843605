// UploadPPT.js
import React, { useEffect, useState, useMemo } from 'react'
import { useApi } from '../../api/api'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import UploadGrey from '../../assets/icons/UploadGrey.svg'
import File from '../../assets/icons/File.svg'
import Trash from '../../assets/icons/Trash.svg'
import { useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Box, LinearProgress } from '@mui/material'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import Input from '../common/Input/Input'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import Typography from '@mui/material/Typography'
import { logEvent } from '../../api/hooks/logs/api'
import { useCanvases } from '../../api/hooks/canvases/useCanvases'
import { setOnboardingChecklist } from '../../store/actions/uiActions'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '300px',
    minWidth: '450px',
    fontSize: '14px',
}

const focusedStyle = {
    borderColor: '#FFA6C3',
    backgroundColor: '#FCE7EE',
}

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#e5fbd7',
}

const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#ffd7d7',
}

// when user uploads an image to S3, make sure the filename is safe
function safeS3FileName(str) {
    return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_')
}

export default function UploadPPT({ isOpen, onClose, canvasName }) {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        },
    })

    // Hook to fetch data
    const { postData: postDataDefault } = useApi()
    const { postData: postDataWorkspaceactions } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions'
    )
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const { refetch } = useCanvases()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [label, setLabel] = useState('')
    const [description, setDescription] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')
    const [step, setStep] = useState(0) // 0 for file upload, 1 for additional details
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)
    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ))
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )

    const handleNext = () => {
        setStep(1)
    }

    const handleBack = () => {
        setStep(0)
    }

    useEffect(() => {
        if (acceptedFiles.length > 0 && label === '') {
            setLabel(acceptedFiles[0].name.replace('.pptx', ''))
        }
    }, [acceptedFiles])

    const handleUpload = async () => {
        setIsLoading(true)
        let payload = {
            action: 'uploadPPT',
            workspaceID: workspaceID,
            canvasName: canvasName,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        try {
            const presignedData = await postDataWorkspaceactions({
                ...payload,
                fileName: safeS3FileName(acceptedFiles[0].name),
                fileType: acceptedFiles[0].type,
            })
            await fetch(presignedData.signedRequest, {
                method: 'PUT',
                body: acceptedFiles[0],
                headers: {
                    'Content-Type': acceptedFiles[0].type,
                },
            })
            showSnackbar(
                'PowerPoint file uploaded successfully! Please wait a few minutes for the new canvas to be created.',
                'success'
            )
            await createCanvasFromPresentation()
        } catch (error) {
            showSnackbar('Error uploading file', 'error')
            setTimeout(() => {
                onClose()
            }, 3000)
        }
    }

    const createCanvasFromPresentation = async () => {
        const action = {
            action: 'createCanvasFromPresentation',
            workspaceID: workspaceID,
            userID: userID,
            label: label,
            description: description,
            fileName: safeS3FileName(acceptedFiles[0].name),
        }
        try {
            const result = await postDataDefault(action)
            if (result?.canvasID) {
                dispatch({
                    type: 'APPLY_LOADED_OBJECTS_STATE',
                    payload: [],
                })
                refetch()
                navigate(result.canvasID, { state: 'newCanvas' })
                logEvent('newCanvasFromPresentation', {
                    type: 'completedNewCanvasFromPresentation',
                    canvas: { canvasID: `${result.canvasID}` },
                    presentation: { presentation: `${result.presentationID}` },
                })
                if (onboardingChecklist === '2') dispatch(setOnboardingChecklist('3'))
            }
            setIsLoading(false)
        } catch (error) {
            console.error('Error creating canvas from presentation:', error)
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return
        setOpenSnackbar(false)
    }

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setOpenSnackbar(true)
    }

    const dialogActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            {step === 0 && (
                <>
                    <Button onClick={onClose} text={'Cancel'} className={'button-grey'} />
                    <Button
                        className={'button-purple'}
                        onClick={handleNext}
                        color="primary"
                        disabled={acceptedFiles.length === 0}
                        text={'Next'}
                    />
                </>
            )}
            {step === 1 && (
                <>
                    <Button onClick={handleBack} className={'button-grey'} disabled={isLoading}>
                        <Typography className={'inter'}>Back</Typography>
                    </Button>
                    <Button onClick={handleUpload} className={'button-purple'} disabled={isLoading}>
                        {isLoading ? (
                            <ScoopLoader />
                        ) : (
                            <Typography className={'inter'}>Create</Typography>
                        )}
                    </Button>
                </>
            )}
        </Box>
    )

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                title={step === 0 ? 'Upload a PowerPoint file' : 'Create canvas'}
                actions={dialogActions}
                style={{ minWidth: '500px' }}
            >
                {step === 0 && (
                    <section className="container">
                        <div {...getRootProps({ className: 'dropzone', style })}>
                            <input {...getInputProps()} />
                            <img src={UploadGrey} alt={'upload'} />
                            <div style={{ margin: '8px 0 16px 0' }}>PPTX</div>
                            <div style={{ color: '#2B1630' }}>
                                Drag 'n' drop your data file here or{' '}
                            </div>
                            <a style={{ color: '#BF0A46', cursor: 'pointer' }}>
                                click here to select
                            </a>
                        </div>
                        {acceptedFiles.length > 0 && (
                            <>
                                <ul>{acceptedFileItems}</ul>
                            </>
                        )}
                    </section>
                )}

                {step === 1 && (
                    <section>
                        <Box>
                            {acceptedFiles.map((file) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column', // Elements are stacked vertically.
                                        padding: '12px 16px',
                                        backgroundColor: '#F9F9F9',
                                        borderRadius: '5px',
                                        marginBottom: '16px',
                                    }}
                                    key={file.path}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <img src={File} alt={'file'} />
                                        <div
                                            style={{
                                                marginLeft: '8px',
                                                flexGrow: 1,
                                                fontSize: '14px',
                                            }}
                                        >
                                            {file.path}
                                        </div>
                                        <img
                                            src={Trash}
                                            alt={'delete'}
                                            style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Input
                                            placeholder={'Presentation name'}
                                            value={label}
                                            onChange={(event) => setLabel(event.target.value)}
                                            fullWidth
                                            inputProps={{
                                                style: { marginBottom: '20px' },
                                            }} // Apply margin directly to input element
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Input
                                            placeholder={'Presentation description'}
                                            value={description}
                                            onChange={(event) => setDescription(event.target.value)}
                                            fullWidth
                                            inputProps={{
                                                style: { marginBottom: '20px' },
                                            }} // Apply margin directly to input element
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </section>
                )}
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}
