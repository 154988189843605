import { botActionsClientRequest } from '../../api'

export const runBot = async (inboxID) => {
    const action = {
        action: 'runBot',
        inboxID: inboxID,
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }
    return await botActionsClientRequest({
        method: 'post',
        data: action,
    })
}
