import Dialog from '../../../common/Dialog/Dialog'
import React, { useCallback, useState } from 'react'
import { Tab } from '../../../common/Tab/Tab'
import { Box, Divider } from '@mui/material'
import Typography from '@mui/material/Typography'
import SearchBar from '../../../common/SeacrhBar/SearchBar'
import { SOURCES_OPTIONS } from '../SourcesOptions'
import { logEvent } from '../../../../api/hooks/logs/api'
import Email from '../../../../assets/icons/EmailDark.svg'
import Database from '../../../../assets/icons/DatabaseBlack.svg'
import Upload from '../../../../assets/icons/UploadDark.svg'
import { debounce } from 'lodash'
import Input from '../../../common/Input/Input'
import Button from '../../../common/Button/Button'
import { useWorkspaces } from '../../../../api/hooks/workspaces/useWorkspaces'
import { useUserInfo } from '../../../../api/hooks/user/useUserInfo'

const tabs = [
    {
        label: 'Available Integrations',
        value: 0,
    },
    {
        label: 'Other ways to connect',
        value: 1,
    },
]

export const CreateDatasetModal = ({ open, close, handleSelectSource, setAlert }) => {
    const SOURCES = SOURCES_OPTIONS.filter(
        (source) => source.name !== 'Database' && source.name !== 'Other'
    )

    const [tab, setTab] = useState(0)
    const [search, setSearch] = useState('')
    const [openRequestIntegration, setOpenRequestIntegration] = useState(false)
    const [integrationRequest, setIntegrationRequest] = useState('')
    const [sources, setSources] = useState(SOURCES)

    const { user } = useUserInfo()
    const { submit, isSubmittingRequest } = useWorkspaces()

    const OTHER_SOURCES = [
        {
            name: 'Data File',
            icon: Upload,
            onClick: () => handleSelectSource('Data File'),
        },
        {
            name: 'Database',
            icon: Database,
            onClick: () => {
                logEvent('newConnectorFlow', {
                    type: 'attemptAPI',
                    api: {
                        connector: 'Database',
                        'action ': 'started new connector flow',
                    },
                })
                handleSelectSource(
                    'Application',
                    SOURCES_OPTIONS.find((source) => source.name === 'Database')
                )
            },
        },
        {
            name: 'Email',
            icon: Email,
            onClick: () => handleSelectSource('Data Email'),
        },
    ]

    const debouncedLogEvent = useCallback(
        debounce((value) => {
            logEvent('newConnectorFlow', {
                type: 'newUnavailableConnectorFlow',
                api: {
                    connector: `${value}`,
                    action: 'started new connector flow for unavailable connector',
                },
            })
        }, 2000),
        []
    )

    const handleSearch = (e) => {
        const value = e.target.value
        setSearch(value)

        const filtered = SOURCES.filter((source) =>
            source.name.toLowerCase().includes(value.toLowerCase())
        )
        setSources(filtered)

        if (filtered.length === 0) {
            debouncedLogEvent(value)
        } else {
            debouncedLogEvent.cancel()
        }
    }

    const handleSourceSelection = (source) => {
        if (!source.isInstalled || (source.isInstalled && !source.allowSingleInstall)) {
            if (source.disabled) {
                setAlert({
                    severity: 'info',
                    message:
                        'This connector is not available in this product release and is coming soon!',
                })
                logEvent('newConnectorFlow', {
                    type: 'newUnavailableConnectorFlow',
                    api: {
                        connector: `${source.name}`,
                        action: 'started new connector flow for unavailable connector',
                    },
                })
                return
            } else {
                logEvent('newConnectorFlow', {
                    type: 'attemptAPI',
                    api: {
                        connector: source.name,
                        'action ': 'started new connector flow',
                    },
                })
            }
            handleSelectSource('Application', source)
        }
    }

    const renderTab0 = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography
                    sx={{
                        color: '#635566',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Inter',
                    }}
                >
                    Click on an integration to connect a data source
                </Typography>
                <Box sx={{ maxWidth: '50%' }}>
                    <SearchBar
                        value={search}
                        onChange={handleSearch}
                        placeholder={'Search integrations (e.g., Salesforce, Google Sheets)'}
                    />
                </Box>
                <Box className={'connections-container'} sx={{ maxHeight: '250px' }}>
                    {sources.length > 0 ? (
                        sources.map((source, index) => (
                            <Box
                                key={index}
                                className={`connections-box ${source.isInstalled && source.allowSingleInstall ? 'source-box-disabled' : ''}`}
                                onClick={() => handleSourceSelection(source)}
                                sx={{
                                    opacity:
                                        source.isInstalled && source.allowSingleInstall ? 0.5 : 1,
                                    cursor:
                                        source.isInstalled && source.allowSingleInstall
                                            ? 'not-allowed'
                                            : 'pointer',
                                    position: 'relative',
                                    maxWidth: '220px',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: source.backgroundColor,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        height: '40px',
                                        width: '40px',
                                    }}
                                >
                                    <img
                                        src={source.icon}
                                        alt={source.name}
                                        className="source-icon"
                                    />
                                </Box>
                                <Typography sx={{ fontSize: '14px' }}>
                                    {source.preferredSpelling || source.name}
                                </Typography>
                                {source.isInstalled && source.allowSingleInstall && (
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '2px',
                                            right: '1px',
                                            fontSize: '10px',
                                            color: '#E50B54',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                        }}
                                    >
                                        Already installed
                                    </Typography>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                            <Typography
                                style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '14px' }}
                            >
                                No results found for "{search}"
                            </Typography>
                            <Typography>
                                Check out ‘Other ways to connect’ to explore additional ways to
                                connect your data. If you need any additional assistance, please
                                contact us at{' '}
                                <a
                                    href="mailto:support@scoopanalytics.com?subject=Connection Request&body=Hi, I’d like to request a connection for..."
                                    style={{
                                        color: '#BF0A46',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                    }}
                                >
                                    support@scoopanalytics.com
                                </a>
                                .
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Divider />
                <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
                    Can't find what you're looking for?{' '}
                    <span
                        onClick={() => {
                            setOpenRequestIntegration(true)
                        }}
                        style={{ color: '#1B06FF', fontWeight: 700, cursor: 'pointer' }}
                    >
                        Request an integration
                    </span>
                </Typography>
            </Box>
        )
    }

    const renderTab1 = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography
                    sx={{
                        color: '#635566',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Inter',
                    }}
                >
                    While we’re always adding new integrations, the options below ensure you can
                    still connect your data seamlessly.
                </Typography>
                <Box className={'connections-container'} sx={{ maxHeight: '250px' }}>
                    {OTHER_SOURCES.map((source, index) => (
                        <Box
                            key={index}
                            className={'connections-box'}
                            onClick={source.onClick}
                            sx={{
                                opacity: 1,
                                cursor: 'pointer',
                                position: 'relative',
                                maxWidth: '220px',
                            }}
                        >
                            <img
                                style={{
                                    backgroundColor: '#F2F2F2',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                src={source.icon}
                                alt={source.name}
                                className="source-icon"
                            />
                            <Typography sx={{ fontSize: '14px' }}>{source.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        )
    }

    const handleSubmitRequest = () => {
        submit({
            email: user?.userName,
            message: integrationRequest,
            onSuccess: () => {
                setIntegrationRequest('')
                setOpenRequestIntegration(false)
                close()
                setAlert({
                    severity: 'success',
                    message: 'Your request has been submitted successfully!',
                })
            },
        })
        logEvent('newConnectorFlow', {
            type: 'newUnavailableConnectorFlow',
            api: {
                connector: 'Request',
                action: integrationRequest,
            },
        })
    }

    return (
        <>
            <Dialog
                style={{ minWidth: 980, height: 600 }}
                open={open && !openRequestIntegration}
                onClose={close}
                title={'Connect Data Source'}
            >
                <Tab
                    noPadding
                    tabs={tabs}
                    value={tab}
                    onChange={(e, newValue) => setTab(newValue)}
                />
                {tab === 0 && renderTab0()}
                {tab === 1 && renderTab1()}
            </Dialog>
            <Dialog
                actions={
                    <>
                        <Button
                            className={'button-grey small'}
                            onClick={() => setOpenRequestIntegration(false)}
                        >
                            Back
                        </Button>
                        <Button
                            disabled={!integrationRequest}
                            className={'button-purple small'}
                            onClick={handleSubmitRequest}
                            loading={isSubmittingRequest}
                        >
                            Submit
                        </Button>
                    </>
                }
                style={{ minWidth: 480, height: 300 }}
                open={openRequestIntegration}
                onClose={() => {
                    setOpenRequestIntegration(false)
                    setIntegrationRequest('')
                    close()
                }}
                title={'Request Integration'}
            >
                <Input
                    placeholder={'Type in details of your request'}
                    onChange={(e) => setIntegrationRequest(e.target.value)}
                    value={integrationRequest}
                    size={'small'}
                    multiline={true}
                    rows={5}
                    padding={'4px'}
                />
            </Dialog>
        </>
    )
}
