import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { switchWorkspace } from '../store/actions/authActions'
import { Box } from '@mui/material'
import { setResetTrigger } from '../store/actions/uiActions'
import Dialog from './common/Dialog/Dialog'
import Selector from './common/Selector/Selector'
import Button from './common/Button/Button'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../router/routes'
import { setWorkspaceID } from '../utils'
import { useWorkspaces } from '../api/hooks/workspaces/useWorkspaces'

// these are hardcoded defaults for when there's a new user and we need to kick off the tour
export const DEFAULT_WORKSPACE_NAME = 'Tour Scoop'
export const OLD_DEFAULT_WORKSPACE_NAME = 'Tour Scoop Workspace'

function SwitchWorkspaceDialog({ shouldOpen, onClose }) {
    const userState = useSelector((state) => state.auth)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const defaultTourWorkspace = useSelector((state) => state.auth.defaultTourWorkspace)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedWorkspace, setSelectedWorkspace] = useState(userState.workspaceID || '')
    const disableClose = workspaceID === null ? true : false

    const { workspaces: workspacesAccessible } = useWorkspaces()

    const getSortedWorkspaces = () => {
        const temp = [...workspacesAccessible]
        temp.sort((a, b) => a.name.localeCompare(b.name))
        return temp
    }

    // If not detault workspace has been selected (i.e. new user)
    useEffect(() => {
        if (workspacesAccessible.length > 0 && selectedWorkspace === '') {
            const tour = workspacesAccessible.find((w) => w.workspaceID === defaultTourWorkspace)
            const tourWorkspace = workspacesAccessible.find(
                (workspace) => workspace.name === DEFAULT_WORKSPACE_NAME
            )
            if (tour) {
                dispatch(switchWorkspace(tour.workspaceID, tour.name))
                setSelectedWorkspace(tour.workspaceID)
                setWorkspaceID(tour.workspaceID)
                /*loadObjects(tour.workspaceID)*/
            } else {
                dispatch(switchWorkspace(tourWorkspace?.workspaceID, DEFAULT_WORKSPACE_NAME))
                setSelectedWorkspace(tourWorkspace?.workspaceID)
                setWorkspaceID(tourWorkspace?.workspaceID)
                /*loadObjects(tourWorkspace?.workspaceID)*/
            }
            dispatch(setResetTrigger())
            onClose()
            navigate(ROUTES.CANVAS_DASHBOARD)
        }
    }, [workspacesAccessible])

    useEffect(() => {
        setSelectedWorkspace(userState.workspaceID)
    }, [userState.workspaceID])

    const handleWorkspaceSelection = (event) => {
        setSelectedWorkspace(event.target.value)
    }

    const inferWorkspaceName = () => {
        const workspace = getSortedWorkspaces().find((ws) => ws.workspaceID === selectedWorkspace)
        return workspace ? workspace.name : null
    }

    const commitWorkspaceSwitch = () => {
        if (typeof selectedWorkspace === 'undefined' || selectedWorkspace === '') {
            // If selectedWorkspace is undefined or empty, select the first workspace from the sorted list
            const firstWorkspace = getSortedWorkspaces()[0]
            if (firstWorkspace) {
                setSelectedWorkspace(firstWorkspace.workspaceID)
                dispatch(switchWorkspace(firstWorkspace.workspaceID, firstWorkspace.name))
                setSelectedWorkspace(firstWorkspace.workspaceID)
            } else {
                console.error('No workspaces available to select.')
            }
        } else {
            // Proceed with the previously selected workspace
            setSelectedWorkspace(selectedWorkspace)
            dispatch(switchWorkspace(selectedWorkspace, inferWorkspaceName()))
        }

        dispatch(setResetTrigger())
        onClose()
        navigate(ROUTES.CANVAS_DASHBOARD)
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            {disableClose ? null : (
                <Button
                    onClick={onClose}
                    className={'primary-button'}
                    text={'Cancel'}
                    disabled={disableClose}
                ></Button>
            )}
            <Button
                onClick={commitWorkspaceSwitch}
                className={'primary-button button-purple'}
                text={'Load Workspace'}
            ></Button>
        </Box>
    )

    return (
        <>
            <Dialog
                onClose={disableClose ? undefined : onClose}
                open={shouldOpen}
                title="Select Workspace"
                actions={actions}
                fullWidth
                disableBackdropClick={disableClose}
                disableEscapeKeyDown={disableClose}
            >
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <Selector
                        value={selectedWorkspace}
                        onChange={handleWorkspaceSelection}
                        label={'Workspaces'}
                        native
                    >
                        {getSortedWorkspaces().map((workspace) => (
                            <option key={workspace.workspaceID} value={workspace.workspaceID}>
                                {workspace.name} ({workspace.description})
                            </option>
                        ))}
                    </Selector>
                </Box>
            </Dialog>
        </>
    )
}

export default SwitchWorkspaceDialog
