import { mobileAPIRequest } from '../../api'

export const createSurveyRequest = async ({ role, goals, companyName, companyWebsite, userID }) => {
    const action = {
        action: 'putObject',
        class: 'scoop.user.UserSurvey',
        value: {
            role,
            goals,
            companyName,
            companyURL: companyWebsite,
            userID,
            timestamp: new Date().toISOString().split('.')[0],
        },
    }

    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
