import { useQuery } from '@tanstack/react-query'
import { getUserInfo, getUserWorkspaces } from './userApi'
import { getUserID } from '../../../utils'
import { useDispatch } from 'react-redux'

export const useUserInfo = () => {
    const userID = getUserID()
    const dispatch = useDispatch()

    const { data: user, refetch: getUser } = useQuery({
        queryKey: ['user', userID],
        queryFn: () => getUserInfo(userID, dispatch),
        enabled: !!userID,
    })

    return {
        user: user || {},
        getUser,
    }
}
